import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Hint};
		color: ${ColorV3.StatusError};
	}
`;
export const FieldError = (p) => {
    if (!p.error) {
        return null;
    }
    return _jsx(Root, { children: p.error }, void 0);
};
