import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { Elevations } from "@/src/enums/elevations";
import { ChatterHistoryHeader } from "@/src/modules/workflow/layout/chat/chatter/ChatterHistoryHeader";
import { ChatterHistoryList } from "@/src/modules/workflow/layout/chat/chatter/ChatterHistoryList";
import { LayoutChatKey } from "@/src/modules/workflow/layout/LayoutChat";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useHistory } from "react-router";
import styled from "styled-components";
const RootWrapper = styled.div `
	& {
		position: relative;
	}
`;
const Root = styled.div `
	& {
		background: ${ColorV3.InputBackgroundEmpty};

		overflow: hidden;

		display: flex;
		flex-direction: column;
		//gap: 4px;

		max-height: 80vh;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 2;
	}

	&.expanded {
		box-shadow: ${Elevations.Tight1};
	}
`;
const RootSpacer = styled.div `
	& {
		visibility: hidden;
	}
`;
export const ChatterHistory = (p) => {
    const history = useHistory();
    const { query } = useQuery();
    const isExpanded = query.get(LayoutChatKey.IsHistory) == "true";
    const handleHeaderClick = () => {
        if (!isExpanded) {
            history.replace(routes.Chat.toPath({ isHistory: true }));
        }
        if (isExpanded) {
            history.replace(routes.Chat.toPath({}));
        }
    };
    return (_jsxs(RootWrapper, { children: [_jsx(RootSpacer, { children: _jsx(ChatterHistoryHeader, { isExpanded: isExpanded }, void 0) }, void 0), _jsxs(Root, Object.assign({ className: `ChatterHistory ${isExpanded && "expanded"}` }, { children: [isExpanded && _jsx(ChatterHistoryHeader, { isExpanded: isExpanded, onClick: handleHeaderClick }, void 0), isExpanded && _jsx(ChatterHistoryList, {}, void 0), _jsx(ChatterHistoryHeader, { isExpanded: isExpanded, onClick: handleHeaderClick }, void 0)] }), void 0)] }, void 0));
};
