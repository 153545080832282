import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { FieldHeader } from "@/src/common/components/field/FieldHeader";
import { Drawer, DrawerAnchorEnum, DrawerContent } from "@/src/features/common/Drawer";
import { t } from "@/src/features/Localization";
import { MFBDrawerContent } from "@/src/features/MFB/MFBButtonDrawerContent";
import { MFBGroupsCard } from "@/src/features/MFB/MFBGroupsCard";
import { useProfile } from "@/src/features/Profile/useProfile";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { useUserEditMutation } from "@/src/modules/user/redux/ApiCoreUser";
import { useLazyGetGroupInstitutionQuery } from "@/src/redux/apiServices/suiteApi";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useEffect, useState } from "react";
import styled from "styled-components";
const MFBButtonContainer = styled.div ``;
const MFBButtonContent = styled.div `
	& {
		align-items: stretch;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
`;
export const MFBButton = ({ saveOnValidate, handleSelectedMFBGroups }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [MFBGroups, setMFBGroups] = useState([]);
    const { profileUser: user, refetchProfile } = useProfile();
    const { segmentTrackEvent } = useSegmentAnalytics();
    const userGuid = user === null || user === void 0 ? void 0 : user.guid;
    const { snackbar } = useCustomSnackbar();
    const [getGroupInstitution, { isError: getGroupInstitutionIsError, isSuccess: getGroupInstitutionIsSuccess, isLoading: getGroupInstitutionIsLoading, data: getGroupInstitutionData },] = useLazyGetGroupInstitutionQuery();
    const [postUserEditDetails, { isSuccess: postUserEditDetailsIsSuccess, data: postUserEditDetailsResponseData }] = useUserEditMutation();
    function handleDrawerClose() {
        setIsDrawerOpen(false);
    }
    function handleDrawerOpen() {
        segmentTrackEvent("select_group_code");
        setIsDrawerOpen(true);
    }
    function handleSubmit(event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        const institutionCode = event === null || event === void 0 ? void 0 : event.target[0].value;
        if (userGuid && institutionCode) {
            getGroupInstitution({ userGuid, institutionCode });
        }
    }
    function handleSaveGroupDetails({ updatedMFBGroups }) {
        var _a;
        const userDetails = user === null || user === void 0 ? void 0 : user.details;
        const userProfileDetails = {
            nameFirst: userDetails === null || userDetails === void 0 ? void 0 : userDetails.nameFirst,
            nameLast: userDetails === null || userDetails === void 0 ? void 0 : userDetails.nameLast,
            phone: userDetails === null || userDetails === void 0 ? void 0 : userDetails.phone,
            dateBirth: userDetails === null || userDetails === void 0 ? void 0 : userDetails.dateBirth,
        };
        const userHealthCardDetails = userDetails === null || userDetails === void 0 ? void 0 : userDetails.healthCard;
        let userProfileDetailsHealthCard;
        if (userHealthCardDetails) {
            const isHaveHealthCard = userHealthCardDetails === null || userHealthCardDetails === void 0 ? void 0 : userHealthCardDetails.isHave;
            const healthCardImageGuids = userHealthCardDetails === null || userHealthCardDetails === void 0 ? void 0 : userHealthCardDetails.files.map((file) => file.guid);
            userProfileDetailsHealthCard = {
                isHave: isHaveHealthCard,
                files: healthCardImageGuids,
            };
        }
        userProfileDetails.healthCard = userProfileDetailsHealthCard;
        const userHealthInsuranceDetails = userDetails === null || userDetails === void 0 ? void 0 : userDetails.healthInsurance;
        let userProfileHealthInsurance;
        if (userHealthInsuranceDetails) {
            const isHaveHealthInsurance = userHealthInsuranceDetails === null || userHealthInsuranceDetails === void 0 ? void 0 : userHealthInsuranceDetails.isHave;
            const healthInsuranceImageGuids = userHealthInsuranceDetails === null || userHealthInsuranceDetails === void 0 ? void 0 : userHealthInsuranceDetails.files.map((file) => file.guid);
            userProfileHealthInsurance = {
                isHave: isHaveHealthInsurance,
                files: healthInsuranceImageGuids,
            };
        }
        userProfileDetails.healthInsurance = userProfileHealthInsurance;
        const userGroups = (_a = user === null || user === void 0 ? void 0 : user.groupRoles) === null || _a === void 0 ? void 0 : _a.map((groupRole) => {
            const group = {
                groupGuid: groupRole.groupGuid,
                roles: groupRole.roles,
            };
            return group;
        });
        const institutionGroups = updatedMFBGroups.map((mFBGroup) => {
            const institutionGroup = {
                groupGuid: mFBGroup.guid,
                roles: ["app-patient"],
            };
            return institutionGroup;
        });
        let updatedRoles = [...institutionGroups];
        if (userGroups && userGroups.length > 0) {
            updatedRoles = [...updatedRoles, ...userGroups];
        }
        // Filter all unique group roles
        updatedRoles = updatedRoles.filter((currentValue, i, accumulator) => accumulator.findIndex((element) => element.groupGuid === currentValue.groupGuid) === i);
        postUserEditDetails({
            guid: userGuid,
            details: userProfileDetails,
            roles: updatedRoles,
        });
    }
    function handleRemoveGroup(groupGuid) {
        const userDetails = user === null || user === void 0 ? void 0 : user.details;
        const userProfileDetails = {
            nameFirst: userDetails === null || userDetails === void 0 ? void 0 : userDetails.nameFirst,
            nameLast: userDetails === null || userDetails === void 0 ? void 0 : userDetails.nameLast,
            phone: userDetails === null || userDetails === void 0 ? void 0 : userDetails.phone,
            dateBirth: userDetails === null || userDetails === void 0 ? void 0 : userDetails.dateBirth,
        };
        const userHealthCardDetails = userDetails === null || userDetails === void 0 ? void 0 : userDetails.healthCard;
        let userProfileDetailsHealthCard;
        if (userHealthCardDetails) {
            const isHaveHealthCard = userHealthCardDetails === null || userHealthCardDetails === void 0 ? void 0 : userHealthCardDetails.isHave;
            const healthCardImageGuids = userHealthCardDetails === null || userHealthCardDetails === void 0 ? void 0 : userHealthCardDetails.files.map((file) => file.guid);
            userProfileDetailsHealthCard = {
                isHave: isHaveHealthCard,
                files: healthCardImageGuids,
            };
        }
        userProfileDetails.healthCard = userProfileDetailsHealthCard;
        const userHealthInsuranceDetails = userDetails === null || userDetails === void 0 ? void 0 : userDetails.healthInsurance;
        let userProfileHealthInsurance;
        if (userHealthInsuranceDetails) {
            const isHaveHealthInsurance = userHealthInsuranceDetails === null || userHealthInsuranceDetails === void 0 ? void 0 : userHealthInsuranceDetails.isHave;
            const healthInsuranceImageGuids = userHealthInsuranceDetails === null || userHealthInsuranceDetails === void 0 ? void 0 : userHealthInsuranceDetails.files.map((file) => file.guid);
            userProfileHealthInsurance = {
                isHave: isHaveHealthInsurance,
                files: healthInsuranceImageGuids,
            };
        }
        userProfileDetails.healthInsurance = userProfileHealthInsurance;
        const updatedMFBGroups = MFBGroups.filter((MFBGroup) => {
            return MFBGroup.guid !== groupGuid;
        });
        // set state on this group here
        setMFBGroups(updatedMFBGroups);
        const updatedGroupRoles = user === null || user === void 0 ? void 0 : user.groupRoles.filter((groupRole) => groupRole.groupGuid !== groupGuid);
        postUserEditDetails({
            guid: userGuid,
            details: userProfileDetails,
            roles: updatedGroupRoles,
        });
    }
    useEffect(() => {
        const groupRoles = (user === null || user === void 0 ? void 0 : user.groupRoles) || [];
        const userGroupInstitutions = groupRoles === null || groupRoles === void 0 ? void 0 : groupRoles.reduce((acc, cv) => {
            const groupAncestors = cv.groupAncestors;
            const groupInstitution = groupAncestors.filter((groupAncestor) => groupAncestor.type === "institution" && groupAncestor.distance === 0);
            return [...acc, ...groupInstitution];
        }, []);
        setMFBGroups(userGroupInstitutions);
        // We will run this whenever this component mounts...
        // OR if the profile user changes.
        // NOTE: there may be a better architecture to not store
        // "MFBGroups" in state and instead rely on fetched data,
        // even after save. But currently, this optimistic approach is working.
    }, [user, userGuid]);
    useEffect(() => {
        var _a;
        if (getGroupInstitutionIsSuccess) {
            const found = MFBGroups.find((MFBGroup) => { var _a, _b; return MFBGroup.guid === ((_b = (_a = getGroupInstitutionData === null || getGroupInstitutionData === void 0 ? void 0 : getGroupInstitutionData.data) === null || _a === void 0 ? void 0 : _a.group) === null || _b === void 0 ? void 0 : _b.guid); });
            if (found) {
                snackbar.error(t("common_mfbInput_error_duplicateCode"));
                handleDrawerClose();
                return;
            }
            snackbar.handleApiMessages(getGroupInstitutionData === null || getGroupInstitutionData === void 0 ? void 0 : getGroupInstitutionData.messages);
            const updatedMFBGroups = [...MFBGroups, (_a = getGroupInstitutionData === null || getGroupInstitutionData === void 0 ? void 0 : getGroupInstitutionData.data) === null || _a === void 0 ? void 0 : _a.group];
            setMFBGroups(updatedMFBGroups);
            // if save on validate logic check here
            if (saveOnValidate) {
                handleSaveGroupDetails({ updatedMFBGroups });
            }
            else if (handleSelectedMFBGroups) {
                handleSelectedMFBGroups(updatedMFBGroups);
            }
            segmentTrackEvent("confirm_group_code");
            handleDrawerClose();
        }
    }, [getGroupInstitutionIsSuccess, getGroupInstitutionData]);
    useEffect(() => {
        if (getGroupInstitutionIsError) {
            if (getGroupInstitutionData === null || getGroupInstitutionData === void 0 ? void 0 : getGroupInstitutionData.messages) {
                snackbar.handleApiMessages(getGroupInstitutionData === null || getGroupInstitutionData === void 0 ? void 0 : getGroupInstitutionData.messages);
            }
            else {
                snackbar.handleApiMessages(getGroupInstitutionData === null || getGroupInstitutionData === void 0 ? void 0 : getGroupInstitutionData.messages);
            }
            handleDrawerClose();
        }
    }, [getGroupInstitutionIsError, getGroupInstitutionData]);
    useEffect(() => {
        if (postUserEditDetailsIsSuccess) {
            snackbar.success(t("profile_message_profileInfoSaved"));
            refetchProfile();
        }
    }, [postUserEditDetailsIsSuccess, postUserEditDetailsResponseData]);
    return (_jsxs(MFBButtonContainer, { children: [_jsxs(MFBButtonContent, { children: [_jsx(FieldHeader
                    //
                    , { 
                        //
                        title: t("profile_tab_insurance_subheader_mfb"), description: t("profile_tab_insurance_description_mfb", {
                            mednowForBusiness: (_jsx(ButtonNavigate
                            //
                            , { 
                                //
                                style: ButtonStyle.TextAnchor, pathTo: "https://mednow.ca/pages/for-business", content: t("profile_tab_insurance_description_mfb_link"), isNewTab: true }, void 0)),
                        }) }, void 0), _jsx(MFBGroupsCard, { groups: MFBGroups, onRemove: handleRemoveGroup }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidGhost, onClick: handleDrawerOpen, content: t("common_mfbInput_button_addCode"), iconLeft: "add" }, void 0)] }, void 0), _jsx(Drawer, Object.assign({ anchor: DrawerAnchorEnum.Bottom, open: isDrawerOpen, onClose: handleDrawerClose }, { children: _jsx(DrawerContent, Object.assign({ isCenterItems: true }, { children: _jsx(MFBDrawerContent, { handleSubmit: handleSubmit, isLoading: getGroupInstitutionIsLoading }, void 0) }), void 0) }), void 0)] }, void 0));
};
