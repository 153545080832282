import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldError } from "@/src/common/components/field/FieldError";
import { FieldHeader } from "@/src/common/components/field/FieldHeader";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { useFormikContext } from "formik";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: stretch;
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 4px;
		//min-width: 100px;
	}

	&.invalid {
		border-color: ${ColorV3.StatusError};
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		flex: 1;
	}
`;
export const FormikField = (p) => {
    const formik = useFormikContext();
    const meta = formik.getFieldMeta(p.name);
    return (_jsxs(Root, Object.assign({ className: "FormikField" }, { children: [_jsx(FieldHeader, { title: p.label, description: p.description }, void 0), _jsx(RootContent, { children: p.children }, void 0), (meta.touched || formik.submitCount >= 1) && _jsx(FieldError, { error: meta.error }, void 0)] }), void 0));
};
