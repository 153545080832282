import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { FAQListItem } from "@/src/features/Faq/FAQListItem";
import styled from "styled-components";
const Root = styled.div `
	border: 1px solid ${ColorV3.Divider};
	border-radius: 3px;
	width: 100%;
`;
export const FAQList = (p) => {
    return (_jsx(Root, { children: p.items.map((item) => {
            return (_jsx(FAQListItem
            //
            , { item: item, onItemSelected: p.onItemSelected, selectedKey: p.selectedItem }, item.key));
        }) }, void 0));
};
