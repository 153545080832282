import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppStoreLinks } from "@/src/common/components/app-store/AppStoreLinks";
import { DividerLabel } from "@/src/common/components/layout/DividerLabel";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { Config } from "@/src/common/utility/config/Config";
import { BreakpointPx, BreakpointPxForMaxWidth } from "@/src/enums/breakpoints";
import { Icons } from "@/src/features/common";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageFullOverflowContainer } from "@/src/features/common/Page/PageFullOverflowContainer";
import { CssBackgroundImagesValues } from "@/src/features/cssBackgroundImages/cssBackgroundImageValues";
import { LoginForm } from "@/src/features/Login/LoginForm";
import { SSOSignInWithButtons } from "@/src/features/Login/SSOSignInWithButtons";
import { CenterItemContainer, NavContainer, NavContent } from "@/src/features/Nav/Nav";
import { VersionAndConditionalBuildId } from "@/src/features/VersionNumber/VersionAndConditionalBuildId";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { LoginAlternatives } from "@/src/modules/user/components/LoginAlernatives";
import { SegmentEntryPoint } from "@/src/utils/SegmentAnalytics";
import styled from "styled-components";
/* --------------------------------- Styles --------------------------------- */
const LoginPageNavContainer = styled(NavContainer) `
	& ${CenterItemContainer} {
		margin: 0 auto;
	}

	// Hide top banner when in Mobile browser width
	@media (max-width: ${BreakpointPxForMaxWidth.Desktop}) {
		display: none;
	}
`;
const MednowHeaderContainer = styled.div `
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	margin-top: 20px;
`;
const MednowLogoMobileOnly = styled(Icons.MednowLogoWithName) `
	margin-top: 30px;

	// Hide this mednowlogo when in Desktop view
	@media (min-width: ${BreakpointPx.Desktop}) {
		display: none;
	}
`;
const LoginPageContainer = styled.div `
	display: flex;
	flex-direction: column;
	min-height: 100%;
	align-items: center;
	margin: 0 auto;
	width: 322px;
`;
const RootStore = styled.div `
	& {
		height: 42px;
		margin: 42px 0;
	}
`;
const VersionAndConditionalBuildIdStyled = styled(VersionAndConditionalBuildId) `
	align-items: center;
	display: flex;
	position: fixed;
	top: 0;
	height: 56px;
	right: 15px;
	z-index: 9999;

	&,
	& * {
		color: ${ColorV3.TextHint} !important;
	}

	@media (min-width: ${BreakpointPx.Desktop}) {
		&,
		& * {
			color: ${ColorV3.Invert} !important;
		}
	}
`;
export const LoginPage = () => {
    return (_jsxs(_Fragment, { children: [Config.DebugInfoIsEnabled && _jsx(VersionAndConditionalBuildIdStyled, {}, void 0), _jsxs(PageContainer, { children: [_jsx(LoginPageNavContainer, { children: _jsx(NavContent, { children: _jsx(CenterItemContainer, { children: _jsx(Icons.MednowLogoWithWhiteName, { width: 132, height: 24 }, void 0) }, void 0) }, void 0) }, void 0), _jsx(PageFullOverflowContainer, Object.assign({ "$backgroundImages": CssBackgroundImagesValues.Page.LoginAndSignup }, { children: _jsxs(LoginPageContainer, Object.assign({ className: "LoginPageContainer" }, { children: [_jsxs(MednowHeaderContainer, { children: [_jsx(MednowLogoMobileOnly, { width: 132, height: 24 }, void 0), _jsx(LayoutContentHeader, { title: "Welcome back" }, void 0)] }, void 0), _jsx(LoginForm, {}, void 0), _jsx(DividerLabel, {}, void 0), _jsx(SSOSignInWithButtons, { isLogin: true }, void 0), _jsx(LoginAlternatives
                                //
                                , { 
                                    //
                                    entrypoint: SegmentEntryPoint.Login, isRegister: true, isPhone: true, isFax: true }, void 0), _jsx(RootStore, { children: _jsx(AppStoreLinks, {}, void 0) }, void 0)] }), void 0) }), void 0)] }, void 0)] }, void 0));
};
