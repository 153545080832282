import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ModalFooterColumn } from "@/src/common/components/modal/ModalFooterColumn";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { t } from "@/src/features/Localization";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
const BoxStyled = styled(Box) `
	position: absolute;
	box-sizing: border-box;
	width: 327px;
	min-height: 240px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${ColorV3.CardBackground};
	border-radius: 7px;
`;
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px;
	}
`;
export const ProfileDeleteModal = (p) => {
    return (_jsx(Modal, Object.assign({ open: p.isOpen, onClose: p.handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsx(BoxStyled, { children: _jsxs(Root, { children: [_jsx(ModalHeader
                    //
                    , { 
                        //
                        title: "Delete your account?", description: "All data, including patient profiles and prescription history, will be deleted." }, void 0), _jsxs(ModalFooterColumn, { children: [_jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.SolidPrimary, content: t("profile_tab_account_dialog_requestAccountDeletion_button_yes"), onClick: p.handleSubmit }, void 0), _jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.TextSubtle, content: t("profile_tab_account_dialog_requestAccountDeletion_button_no"), onClick: p.handleClose }, void 0)] }, void 0), _jsx(ModalHeader, { description: "Please note, your account will remain active until a member of our team completes your deletion request." }, void 0)] }, void 0) }, void 0) }), void 0));
};
