var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldHeader } from "@/src/common/components/field/FieldHeader";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { consoleCatch } from "@/src/common/utility/log/Log";
import { ErrorMessage } from "@/src/features/common/ErrorMessage";
import * as Icons from "@/src/features/common/Icons";
import ImageModal from "@/src/features/common/ImageModal";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import { t } from "@/src/features/Localization";
import { usePutFileUploadMutation } from "@/src/redux/apiServices/suiteApi";
import { addFileGuid, removeFileGuid } from "@/src/redux/slices/fileSlice";
import { resolveValue } from "@/src/utils/PathValue";
import { useFormikContext } from "formik";
import { Fragment, useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useDispatch } from "react-redux";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
const RootWrapper = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
const RootContent = styled.div `
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
const ImageItem = styled.div `
	display: flex;
	position: relative;
	border-radius: 4px;
`;
const Image = styled.img `
	border-radius: 4px;
	border: 1px solid ${ColorV3.Divider};
	display: block;
`;
const ImageButtonRemove = styled.button `
	position: absolute;
	right: 26px;
	top: 2px;
	border: none;
	padding: 0;
	z-index: 2;
`;
const ImageButtonRemoveBackground = styled.div `
	position: absolute;
	border: none;
	padding: 0;
	background: white;
	border-radius: 20%;
	z-index: 1;
	height: 15px;
	width: 15px;
	top: 4px;
	left: 4px;
`;
const IconsCancelRounded = styled(Icons.CancelRounded) `
	width: 24px;
	height: 24px;
	position: absolute;
	z-index: 10;
`;
const RootUploadContent = styled.button `
	align-items: center;
	display: flex;
	flex-direction: column;
	width: ${(props) => (props.isFullWidth ? "100%" : "96px")};
	${(props) => (props.isFullWidth ? "" : "height: 96px")};
	justify-content: center;
	border: 1px dashed ${ColorV3.ButtonPrimary};
	border-radius: 8px;
	padding: 0;
	${(props) => (props.isFullWidth ? "aspect-ratio: 3" : "")};
`;
const RootUploadLabel = styled.div `
	border: 1px solid ${ColorV3.Divider};
	border-radius: 8px;
	color: ${ColorV3.TextHint};
	font-size: 14px;
	font-weight: 500;
	margin-top: 10px;
	padding: 8px 12px;
`;
const LoaderSpinnerContainer = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	width: 96px;
	height: 96px;
`;
const RenderUploadedImgs = ({ imageFiles, onImageRemoveCustom }) => {
    return (_jsx(Fragment, { children: imageFiles === null || imageFiles === void 0 ? void 0 : imageFiles.map((image, index) => (_jsxs(ImageItem, { children: [_jsx(ImageModal, Object.assign({ imageSrc: image.url }, { children: _jsx(Image, { src: image.url, alt: `Image ${index}`, width: "96", height: "96" }, void 0) }), void 0), _jsxs(ImageButtonRemove, Object.assign({ onClick: () => {
                        onImageRemoveCustom(index);
                    }, type: "button" }, { children: [_jsx(ImageButtonRemoveBackground, {}, void 0), _jsx(IconsCancelRounded, { color: "black" }, void 0)] }), void 0)] }, index))) }, void 0));
};
export const FormikImageUploader = (p) => {
    var _a;
    const formikId = p.formikId;
    const imageType = p.imageType;
    const title = p.title;
    const helperText = p.description;
    const isHideUpload = p.isHideUpload;
    const isFullWidth = p.isFullWidth;
    const maxImageNumber = (_a = p.maxImageNumber) !== null && _a !== void 0 ? _a : 10;
    const dispatch = useDispatch();
    const { setFieldValue, values, touched, errors } = useFormikContext();
    const [isLoadingImageAdded, setIsLoadingImageAdded] = useState(false);
    const [imageFiles, setImageFiles] = useState(resolveValue(values, formikId) ? resolveValue(values, formikId) : []);
    const errorMessage = resolveValue(touched, formikId) && resolveValue(errors, formikId);
    const [apiFileUpload, apiFileUploadState] = usePutFileUploadMutation();
    const onImageChange = (imageList, addUpdatedIndex) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoadingImageAdded(true);
        if (!addUpdatedIndex) {
            yield setFieldValue(formikId, imageList);
            return;
        }
        const image = imageList[addUpdatedIndex[0]];
        const file = image.file;
        apiFileUpload({
            body: file,
            mimeType: file.type,
            contentSize: file.size,
        });
    });
    const onImageRemoveCustom = (index) => __awaiter(void 0, void 0, void 0, function* () {
        const tempImageFiles = [...imageFiles];
        const imageFileToRemove = tempImageFiles[index];
        tempImageFiles.splice(index, 1);
        const imageFileToRemoveGuid = imageFileToRemove.guid;
        dispatch(removeFileGuid({ fileGuid: imageFileToRemoveGuid, fileType: imageType }));
        yield setFieldValue(formikId, tempImageFiles);
        setImageFiles(tempImageFiles);
    });
    useEffect(() => {
        var _a, _b;
        if (apiFileUploadState.isSuccess) {
            const uploadedFile = (_b = (_a = apiFileUploadState.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.file;
            dispatch(addFileGuid({ fileGuid: uploadedFile.guid, fileType: imageType }));
            const formattedFile = {
                guid: uploadedFile.guid,
                mimeType: uploadedFile.mimeType,
                url: uploadedFile.urlFull,
            };
            setImageFiles((prevImageFiles) => [...prevImageFiles, formattedFile]);
            setIsLoadingImageAdded(false);
        }
    }, [apiFileUploadState.isSuccess, apiFileUploadState.data]);
    useEffect(() => {
        setFieldValue(formikId, imageFiles).catch(consoleCatch);
    }, [imageFiles, setImageFiles]);
    return (_jsxs(Root, { children: [_jsxs(RootWrapper, { children: [_jsx(FieldHeader, { title: title, description: helperText }, void 0), !isHideUpload && (_jsx(ImageUploading, Object.assign({ value: resolveValue(values, formikId), onChange: onImageChange, maxNumber: maxImageNumber, dataURLKey: "data_url", acceptType: ["jpg", "jpeg", "png", "heic", "heif"], maxFileSize: 10000000 }, { children: ({ onImageUpload, errors: uploaderError }) => (_jsxs(RootContent, { children: [!isFullWidth && _jsx(RenderUploadedImgs, { imageFiles: imageFiles, onImageRemoveCustom: onImageRemoveCustom }, void 0), (isLoadingImageAdded || apiFileUploadState.isLoading) && !isFullWidth ? (_jsx(LoaderSpinnerContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0)) : (_jsxs(RootUploadContent, Object.assign({ onClick: onImageUpload, type: "button", isFullWidth: isFullWidth }, { children: [_jsx(Symbol, { icon: "add_a_photo", color: ColorV3.Icon }, void 0), isFullWidth && _jsx(RootUploadLabel, { children: "Upload Image" }, void 0)] }), void 0)), isFullWidth && _jsx(RenderUploadedImgs, { imageFiles: imageFiles, onImageRemoveCustom: onImageRemoveCustom }, void 0), (isLoadingImageAdded || apiFileUploadState.isLoading) && isFullWidth && (_jsx(LoaderSpinnerContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0)), uploaderError && uploaderError.maxFileSize && _jsx(ErrorMessage, { error: t("common_imageUploader_error_maxFileSize") }, void 0), uploaderError && uploaderError.maxNumber && _jsx(ErrorMessage, { error: `Number of selected images exceeds max number: ${maxImageNumber}` }, void 0)] }, void 0)) }), void 0))] }, void 0), errorMessage && _jsx(ErrorMessage, { error: errorMessage }, void 0)] }, void 0));
};
