import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { routes } from "@/src/routes";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Button};
		align-items: stretch;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: auto;
		padding: 24px 0;
	}
`;
export const HomeFooter = () => {
    const { segmentTrackEvent } = useSegmentAnalytics();
    return (_jsxs(Root, { children: [_jsx(ButtonNavigate, { style: ButtonStyle.SolidGhost, pathTo: routes.Chat.toPath({}), contentLeft: _jsx(Symbol, { icon: "chat_bubble" }, void 0), content: "Chat with our Pharmacy" }, void 0), _jsx(ButtonNavigate
            //
            , { 
                //
                style: ButtonStyle.TextSubtle, pathTo: routes.Faq.toPath({ key: "dispensing-fee" }), content: "What is the dispensing fee?" }, void 0)] }, void 0));
};
