import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import styled from "styled-components";
const RadioGroupContentContainer = styled.div ``;
const FormControlLabelStyled = styled(FormControlLabel) `
	&& .MuiRadio-colorPrimary {
		color: ${ColorV3.ButtonPrimary};
	}

	&& .MuiTypography-root {
		font-family: var(--font-family-secondary);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 21px;
		letter-spacing: 0.03em;
		text-transform: uppercase;
	}
`;
/** @deprecated TODO: reidenzon - Use {@link FormikRadio} instead! */
export const RadioButtonsGroup = ({ childValues, handleChange, value, name, label }) => {
    return (_jsx(FormControl, { children: _jsx(RadioGroup, Object.assign({ "aria-labelledby": label, name: name, value: value, onChange: handleChange }, { children: childValues.map((childValue) => {
                return (_jsxs(RadioGroupContentContainer, { children: [_jsx(FormControlLabelStyled, { value: childValue.value, control: _jsx(Radio, {}, void 0), label: childValue.label }, void 0), childValue.component] }, childValue.label));
            }) }), void 0) }, void 0));
};
