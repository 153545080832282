import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridRow } from "@/src/common/components/grid/GridRow";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { getTimeStampAsLocaleDate } from "@/src/common/utility/time/TimeStamp";
import { ChatterHistoryItemContent } from "@/src/modules/workflow/layout/chat/chatter/ChatterHistoryItemContent";
import styled from "styled-components";
const Root = styled.div `
	& {
		background: white;
		border-radius: 3px;
		border: 1px solid ${ColorV3.Divider};
	}

	&.expanded {
	}
`;
const RootHeader = styled.div `
	& {
		${TypographyV3.Badge};
		align-items: center;
		color: ${ColorV3.TextBody};
		cursor: pointer;
		display: flex;
		flex-direction: row;
		font-size: 0.8em;
		gap: 4px;
		justify-content: space-between;
		padding: 2px 4px;
	}

	&:hover {
		//background: rgba(0, 0, 0, 0.05);
	}

	${Root}.expanded & {
		border-bottom: 1px solid ${ColorV3.Divider};
	}
`;
const RootTopic = styled.div `
	& {
		color: ${ColorV3.TextHeader};
		font-weight: 600;
	}
`;
export const ChatterHistoryItem = (p) => {
    var _a;
    const w = p.workflow;
    const isExpanded = p.isExpanded;
    return (_jsxs(Root, Object.assign({ className: `${isExpanded && "expanded"}` }, { children: [_jsxs(RootHeader, Object.assign({ onClick: p.onClick }, { children: [_jsxs("div", { children: [_jsx(RootTopic, { children: (_a = w.details.topic) === null || _a === void 0 ? void 0 : _a.caption }, void 0), _jsx(GridRow, { children: getTimeStampAsLocaleDate(w.timeReceived * 1000) }, void 0)] }, void 0), !isExpanded && _jsx(Symbol, { icon: "expand_more", color: ColorV3.Icon }, void 0), isExpanded && _jsx(Symbol, { icon: "expand_less", color: ColorV3.Icon }, void 0)] }), void 0), isExpanded && _jsx(ChatterHistoryItemContent, { workflow: w }, void 0)] }), void 0));
};
