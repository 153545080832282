import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	${TypographyV3.Body};
	color: ${ColorV3.ButtonPrimary};
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
`;
const RootLine = styled.div `
	width: 70px;
	height: 1px;
	background-color: ${ColorV3.ButtonPrimary};
	margin: 0 8px;
`;
export const DividerLabel = (p) => {
    return (_jsxs(Root, { children: [_jsx(RootLine, {}, void 0), "or", _jsx(RootLine, {}, void 0)] }, void 0));
};
