import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import { ConfirmDialog } from "@/src/features/common/ConfirmDialog";
import { t } from "@/src/features/Localization";
import { useDeleteUserPaymentMethodMutation } from "@/src/redux/apiServices/suiteApi";
import { useEffect, useState } from "react";
import styled from "styled-components";
const Root = styled.div ``;
const RootContent = styled.div `
	${TypographyV3.Body};
	background-color: ${({ $isProfile }) => ($isProfile ? ColorV3.BadgeBackground : ColorV3.DisabledBackground)};
	border: 1px solid ${ColorV3.Divider};
	max-width: 332px;
	padding: 16px;
	border-radius: 8px;
	margin-bottom: 40px;
`;
const RootHeader = styled.div `
	align-items: start;
	display: flex;
	justify-content: space-between;
`;
const RootImage = styled.div ``;
const RootBody = styled.div `
	${TypographyV3.Body};
	align-items: center;
	display: flex;
	font-weight: 600;
	justify-content: space-between;
	margin-top: 20px;
`;
const RootNumber = styled.div `
	& {
	}
`;
const RootExpiry = styled.div `
	& {
	}
`;
export const PaymentMethodCard = ({ paymentMethods, refetchCurrentUser, user, userPaymentMethodRefetch, isProfile }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteUserPaymentMethod, { isSuccess: isSuccessDeleteUserPaymentMethod }] = useDeleteUserPaymentMethodMutation();
    const paymentMethod = paymentMethods[0];
    const { card, id: paymentMethodId } = paymentMethod;
    const { brand, last4, exp_month: expMonth, exp_year: expYear } = card;
    const formattedExpMonth = expMonth.length === 1 ? `0${expMonth}` : expMonth;
    const formattedExpYear = expYear.toString().slice(2, 4);
    const expiry = `${formattedExpMonth}/${formattedExpYear}`;
    function getCardIconImage({ cardBrandType }) {
        switch (cardBrandType) {
            case "visa":
                return _jsx(Icons.WalletVisa, { size: 40 }, void 0);
            case "mastercard":
                return _jsx(Icons.WalletMasterCard, { size: 40 }, void 0);
            case "amex":
                return _jsx(Icons.WalletAmericanExpress, { size: 40 }, void 0);
            case "diners":
                return _jsx(Icons.WalletDinersClub, { size: 40 }, void 0);
            case "discover":
                return _jsx(Icons.WalletDiscover, { size: 40 }, void 0);
            case "jcb":
                return _jsx(Icons.WalletJCB, { size: 40 }, void 0);
            case "unionpay":
                return _jsx(Icons.WalletUnionPay, { size: 40 }, void 0);
            default:
                return _jsx(Icons.WalletAmericanExpress, { size: 40 }, void 0);
        }
    }
    function handleSetModalClosed() {
        setIsModalOpen(false);
    }
    function handleSetModalOpen() {
        setIsModalOpen(true);
    }
    function handleCardRemove() {
        if (user && paymentMethodId) {
            deleteUserPaymentMethod({ userFor: user === null || user === void 0 ? void 0 : user.guid, paymentMethodId });
        }
        handleSetModalClosed();
    }
    useEffect(() => {
        if (isSuccessDeleteUserPaymentMethod) {
            if (userPaymentMethodRefetch) {
                userPaymentMethodRefetch();
            }
            refetchCurrentUser();
        }
    }, [isSuccessDeleteUserPaymentMethod]);
    return (_jsxs(Root, { children: [_jsxs(RootContent, Object.assign({ "$isProfile": isProfile }, { children: [_jsxs(RootHeader, { children: [_jsx(RootImage, { children: getCardIconImage({ cardBrandType: brand }) }, void 0), isProfile && _jsx(ButtonStandard, { style: ButtonStyle.TextDanger, content: "Remove", onClick: handleSetModalOpen }, void 0)] }, void 0), _jsxs(RootBody, { children: [_jsx(RootNumber, { children: t("common_paymentMethodCard_last4", { last4 }) }, void 0), _jsx(RootExpiry, { children: t("common_paymentMethodCard_expiry", { expiry }) }, void 0)] }, void 0)] }), void 0), _jsx(ConfirmDialog, { isOpen: isModalOpen, handleClose: handleSetModalClosed, handleSubmit: handleCardRemove, titleText: t("common_paymentMethodCard_dialog_remove_header"), informationText: t("common_paymentMethodCard_dialog_remove_content"), buttonTextConfirm: t("common_paymentMethodCard_dialog_remove_button_yes"), buttonTextCancel: t("common_paymentMethodCard_dialog_remove_button_no") }, void 0)] }, void 0));
};
