import { ColorV3 } from "@/src/common/style/StyleColor";
import { createGlobalStyle } from "styled-components";
export const CssGlobalStyle = createGlobalStyle `
	/**
	 * Many css variables will be placed here.
	 * In the future these values can be updated.
	 */
	:root {
		--nav-height: 56px;
		--footer-height: 100px;
		--color-text-primary: ${ColorV3.TextBody};

		// Snackbar styles
		--color-text-status-success: ${ColorV3.StatusSuccess};
		--color-text-status-error: ${ColorV3.StatusError};
		--color-text-status-warning: ${ColorV3.StatusWarning};
		--color-text-status-info: ${ColorV3.StatusInfo};

		--font-family-primary: "Noto Serif", serif;
		--font-family-secondary: "Inter", sans-serif;
	}

	html {
		width: 100%;
		height: 100%;
		display: table;
	}

	body {
		width: 100%;
		display: table-cell;
		// Required as part of ensure no page scroll (to keep height 100%)
		position: relative;
	}

	html, body {
		margin: 0;
		padding: 0;
		// To prevent page scroll
		// overflow-x: hidden;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	/** 
	 * NOTE: Any changes to the 'body' style should potentially 
	 * also be changed in index.ejs, so if the app crashes the 
	 * default body style is shown.
	 */
	body {
		color: var(--color-text-primary);

		font-family: var(--font-family-primary);
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 1.4;

		word-break: break-word;

		margin: 0;
	}

	button {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		cursor: pointer;
		color: ${ColorV3.ButtonPrimary};
	}

	#root {
		min-height: 100%;
		height: 100%;
	}

	:focus-visible {
		outline: ${ColorV3.ButtonPrimaryHover} auto 1px;
	}
`;
