import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import { ListCTAItemLabel } from "@/src/features/common/ListCTA/ListCTAItemLabel";
import styled from "styled-components";
/* ------------------------- Variables and Constants ------------------------ */
// This is the affect on button when it is hovered.
const containerHoverOpacity = 0.65;
const DEFAULT_ICON_COLOR = ColorV3.Icon;
export const DEFAULT_ICON_LEFT_COLOR = DEFAULT_ICON_COLOR;
export const DEFAULT_ICON_RIGHT_COLOR = DEFAULT_ICON_COLOR;
export const DEFAULT_ICON_LEFT_SIZE = 24;
export const DEFAULT_ICON_RIGHT_SIZE = 16;
/* --------------------------------- Styles --------------------------------- */
const ListCTAItemLeftIcon = styled.span `
	margin-right: 12px;
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
`;
const ListCTAItemText = styled.span `
	${TypographyV3.Body};
`;
const ListCTAItemRightContainer = styled.span `
	display: flex;
	flex-direction: row;
	margin-left: auto;
`;
const ListCTAItemRightIcon = styled.span `
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 12px;
`;
const ListCTAItemContentContainer = styled.span `
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
`;
const Root = styled.button `
	display: flex;
	align-items: center;
	height: 56px;
	width: 100%;
	padding: 12px 0;
	border-bottom: 1px solid ${ColorV3.Divider};

	&:hover ${ListCTAItemContentContainer} {
		opacity: ${containerHoverOpacity};
	}
`;
export const ListCTAItem = (p) => {
    const rightIcon = p.rightIcon || _jsx(Icons.ChevronRight, { color: DEFAULT_ICON_COLOR, size: DEFAULT_ICON_RIGHT_SIZE }, void 0);
    if (!p.isShow) {
        return null;
    }
    // label
    const { text: labelText, labelColorType, isLoading: isLoadingLabel } = p.label || {};
    return (_jsx(Root, Object.assign({ type: "button", onClick: p.onClick }, { children: _jsxs(ListCTAItemContentContainer, { children: [_jsx(ListCTAItemLeftIcon, Object.assign({ className: "ListCTAItemLeftIcon" }, { children: p.leftIcon }), void 0), p.text && _jsx(ListCTAItemText, { children: p.text }, void 0), _jsxs(ListCTAItemRightContainer, { children: [_jsx(ListCTAItemLabel, { text: labelText, labelColorType: labelColorType, isLoading: isLoadingLabel }, void 0), _jsx(ListCTAItemRightIcon, { children: rightIcon }, void 0)] }, void 0)] }, void 0) }), void 0));
};
