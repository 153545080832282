import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { t } from "@/src/features/Localization";
import { SendSnackbar } from "@/src/features/utils/CustomSnackbar";
import { apiHasANewerVersion } from "@/src/utils/BuildVersionHelpers";
import debounce from "lodash.debounce";
import { useCallback } from "react";
import styled from "styled-components";
/* -------------------------------- Constants ------------------------------- */
const DEBOUNCE_TIME = 5000;
/* -------------------------------- Styles ------------------------------- */
const Link = styled.a `
	color: ${ColorV3.Invert};

	&:visited {
		color: ${ColorV3.Invert};
	}
`;
/* -------------------------------- Helpers ------------------------------- */
const showSnackbar = debounce((newSearchValue) => {
    SendSnackbar.info(newSearchValue);
}, DEBOUNCE_TIME, {
    leading: true,
    trailing: false,
});
function NewVersionMessage() {
    const reload = useCallback((e) => {
        e.preventDefault();
        window.location.reload();
    }, []);
    const link = (_jsx(Link, Object.assign({ href: "/", onClick: reload }, { children: t("common_app_reload_snackbar_message_link") }), void 0));
    return _jsx("span", { children: t("common_app_reload_snackbar_message", { link }) }, void 0);
}
export const interceptAPIVersionMiddleware = () => {
    return (next) => (action) => {
        var _a;
        if ((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.apiVersion) {
            const apiVersion = action.payload.apiVersion;
            if (apiHasANewerVersion(apiVersion)) {
                showSnackbar(_jsx(NewVersionMessage, {}, void 0));
            }
        }
        return next(action);
    };
};
