import { createSlice } from "@reduxjs/toolkit";
import { ReduxSliceName } from "@/src/enums/redux";
const FileInfoInitialState = {
    fileGuids: {},
};
const slice = createSlice({
    name: ReduxSliceName.FileInfo,
    initialState: FileInfoInitialState,
    reducers: {
        addFileGuid: (state, { payload: { fileGuid, fileType }, }) => {
            if (state.fileGuids[fileType]) {
                state.fileGuids[fileType] = [...state.fileGuids[fileType], fileGuid];
            }
            else {
                state.fileGuids[fileType] = [fileGuid];
            }
        },
        addFileGuids: (state, { payload: { fileGuids, fileType }, }) => {
            if (state.fileGuids[fileType]) {
                state.fileGuids[fileType] = [
                    ...state.fileGuids[fileType],
                    ...fileGuids,
                ];
            }
            else {
                state.fileGuids[fileType] = [...fileGuids];
            }
        },
        removeFileGuid: (state, { payload: { fileGuid, fileType }, }) => {
            if (state.fileGuids[fileType]) {
                const tempFileGuids = state.fileGuids[fileType];
                const filteredFileGuids = tempFileGuids.filter((guid) => fileGuid !== guid);
                state.fileGuids[fileType] = filteredFileGuids;
            }
        },
        clearFileGuidsForFileTypes: (state, { payload: { fileTypes } }) => {
            fileTypes.forEach((fileType) => {
                var _a;
                if ((_a = state.fileGuids) === null || _a === void 0 ? void 0 : _a[fileType]) {
                    state.fileGuids[fileType] = [];
                }
            });
        },
        clearFileGuids: (state) => {
            state.fileGuids = {};
        },
    },
});
export const { addFileGuid, addFileGuids, clearFileGuids, clearFileGuidsForFileTypes, removeFileGuid, } = slice.actions;
export default slice.reducer;
export const getFileGuids = (state) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state[ReduxSliceName.FileInfo]) === null || _a === void 0 ? void 0 : _a.fileGuids; };
