import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Elevations } from "@/src/enums/elevations";
import { LoaderSpinnerCentered } from "@/src/features/common/LoaderSpinnerCentered";
import styled from "styled-components";
const FEATURE_CARD_MIN_HEIGHT_PX = "176px";
const Root = styled.div `
	display: flex;
	flex-direction: column;
	background-color: ${ColorV3.CardBackground};
	border-radius: 4px;
	padding: 24px;
	min-height: ${FEATURE_CARD_MIN_HEIGHT_PX};
	box-sizing: border-box;
	box-shadow: ${Elevations.FeatureCard};
`;
const RootTitle = styled.div `
	${TypographyV3.Header3}
`;
const RootBody = styled.div `
	${TypographyV3.Body}
	* + & {
		margin-top: 8px;
	}
`;
const RootButtons = styled.div `
	${TypographyV3.Button};
	display: flex;
	gap: 32px;

	* + & {
		padding-top: 24px;
		margin-top: auto;
	}
`;
export const FeatureCard = (p) => {
    if (p.isLoading) {
        return (_jsx(Root, { children: _jsx(LoaderSpinnerCentered, {}, void 0) }, void 0));
    }
    return (_jsxs(Root, { children: [p.title && _jsx(RootTitle, { children: p.title }, void 0), p.content && _jsx(RootBody, { children: p.content }, void 0), p.buttons && _jsx(RootButtons, { children: p.buttons }, void 0)] }, void 0));
};
