import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { Icons } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.button `
	align-items: center;
	display: flex;
	justify-content: center;
	border-radius: 4px;
	height: 24px;
	width: 24px;
	box-sizing: border-box;
	background-color: ${({ $checked }) => ($checked ? ColorV3.Icon : "transparent")};
	border: ${({ $checked }) => ($checked ? "0" : `0.5px solid ${ColorV3.Icon}`)};
	padding: 0;

	&:disabled {
		background-color: ${ColorV3.DisabledBackground};
		border-color: ${ColorV3.Disabled};
	}
`;
const IconStyled = styled(Icons.CheckRounded) `
	&&.MuiSvgIcon-root {
		fill: ${({ $checked }) => ($checked ? ColorV3.DisabledBackground : ColorV3.CardBackground)};
	}
`;
export const Checkbox = (p) => {
    return (_jsx(Root, Object.assign({ type: "button", onClick: () => {
            if (p.onChange !== undefined && !p.isDisabled) {
                p.onChange(!p.checked);
            }
        }, disabled: p.isDisabled, "$checked": p.checked }, { children: p.checked ? _jsx(IconStyled, { color: "white", "$checked": p.checked }, void 0) : "" }), void 0));
};
