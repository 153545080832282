var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { consoleCatch } from "@/src/common/utility/log/Log";
import { ChatterHistoryEmpty } from "@/src/modules/workflow/layout/chat/chatter/ChatterHistoryEmpty";
import { ChatterHistoryItem } from "@/src/modules/workflow/layout/chat/chatter/ChatterHistoryItem";
import { LayoutChatKey } from "@/src/modules/workflow/layout/LayoutChat";
import { useLazyWorkflowChatHistoryQuery } from "@/src/modules/workflow/redux/ApiCoreWorkflow";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	& {
		border: 1px solid ${ColorV3.Divider};
		border-top: none;

		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 8px;

		overflow: auto;
		padding: 8px;
	}
`;
export const ChatterHistoryList = (p) => {
    var _a;
    const history = useHistory();
    const { query } = useQuery();
    const selected = query.get(LayoutChatKey.HistoryGuid);
    const limit = 10;
    const [page, setPage] = useState(0);
    const [apiList, apiListState] = useLazyWorkflowChatHistoryQuery();
    const isMoreAvailable = (_a = apiListState.data) === null || _a === void 0 ? void 0 : _a.data.isMoreAvailable;
    const isMore = isMoreAvailable !== false;
    const [workflowListTotal, setWorkflowListTotal] = useState([]);
    const workflowListReversed = [...workflowListTotal].reverse();
    const isEmpty = workflowListReversed.length == 0;
    const handleList = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = (yield apiList({
            limit: limit,
            offset: limit * page,
        }).unwrap());
        const workflowList = response.data.workflows;
        setWorkflowListTotal((wl) => [...wl, ...workflowList]);
    });
    const handleMore = () => __awaiter(void 0, void 0, void 0, function* () {
        setPage(page + 1);
    });
    useEffect(() => {
        handleList().catch(consoleCatch);
    }, [page]);
    return (_jsxs(Root, { children: [isEmpty && _jsx(ChatterHistoryEmpty, {}, void 0), isMore && _jsx(ButtonStandard, { style: ButtonStyle.SolidGhost, content: "More", onClick: handleMore }, void 0), workflowListReversed.map((w) => {
                const isExpanded = w.guid == selected;
                return (_jsx(ChatterHistoryItem
                //
                , { isExpanded: isExpanded, onClick: () => {
                        if (!isExpanded) {
                            history.replace(routes.Chat.toPath({
                                isHistory: true,
                                historyGuid: w.guid,
                            }));
                        }
                        if (isExpanded) {
                            history.replace(routes.Chat.toPath({ isHistory: true }));
                        }
                    }, workflow: w }, w.guid));
            })] }, void 0));
};
