import { ColorV3 } from "@/src/common/style/StyleColor";
import { css } from "styled-components";
export const TypographyV3 = {
    Header1: css `
		color: ${ColorV3.TextHeader};
		font-family: var(--font-family-primary);
		font-size: 1.6rem;
		font-weight: 500;
		line-height: 1.2;
	`,
    Header2: css `
		color: ${ColorV3.TextHeader};
		font-family: var(--font-family-primary);
		font-size: 1.5rem;
		font-weight: 500;
		line-height: 1.2;
	`,
    Header3: css `
		color: ${ColorV3.TextHeader};
		font-family: var(--font-family-primary);
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1.2;
	`,
    Header6: css `
		color: ${ColorV3.TextHeader};
		font-family: var(--font-family-primary);
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.2;
	`,
    Body: css `
		color: ${ColorV3.TextBody};
		font-family: var(--font-family-secondary);
		font-size: 0.8rem;
		font-weight: 400;
	`,
    Hint: css `
		color: ${ColorV3.TextHint};
		font-family: var(--font-family-secondary);
		font-size: 0.8rem;
		font-weight: 500;
	`,
    FieldTitle: css `
		color: ${ColorV3.TextHeader};
		font-family: var(--font-family-primary);
		font-size: 1.1rem;
		font-weight: 500;
		line-height: 1.2;
	`,
    FieldDescription: css `
		color: ${ColorV3.TextBody};
		font-family: var(--font-family-secondary);
		font-size: 0.8rem;
		font-weight: 400;
		line-height: 1.2;
	`,
    FieldInput: css `
		color: ${ColorV3.TextBody};
		font-family: var(--font-family-secondary);
		font-size: 0.8rem;
		font-weight: 400;
		line-height: 1.4;
	`,
    Button: css `
		font-family: var(--font-family-secondary);
		font-size: 0.8rem;
		font-weight: 500;
	`,
    Badge: css `
		font-family: var(--font-family-secondary);
		font-size: 0.7rem;
		font-weight: 500;
	`,
};
