import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { WorkflowStep } from "@/src/modules/workflow/layout/workflow/stepper/WorkflowStep";
import { WorkflowStepState } from "@/src/modules/workflow/layout/workflow/stepper/WorkflowStepState";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		background-color: ${ColorV3.CardBackground};
		border-bottom: 1px solid ${ColorV3.Divider};
		display: flex;
		height: 72px;
		z-index: 9;
		position: relative;
		justify-content: center;
		gap: 24px;
	}
`;
export const WorkflowStepper = (p) => {
    const stepCurrent = p.step || 0;
    const stepLength = p.steps.length;
    return (_jsx(Root, { children: p.steps.map((stepInfo, stepIndex) => {
            const isLast = stepIndex == stepLength - 1;
            const state = getStateInfo(p.steps, stepCurrent, stepIndex);
            return _jsx(WorkflowStep, { isLast: isLast, state: state, step: stepInfo }, stepIndex);
        }) }, void 0));
};
const getStateInfo = (steps, stepCurrent, stepIndex) => {
    let state;
    if (stepIndex < stepCurrent) {
        state = WorkflowStepState.Complete;
    }
    else if (stepIndex == stepCurrent) {
        state = WorkflowStepState.Current;
    }
    else {
        state = WorkflowStepState.Pending;
    }
    return state;
};
