import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { ChipSmall } from "@/src/features/common/Chip";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Header6};
		align-items: center;
		color: ${ColorV3.Invert};
		cursor: pointer;
		display: flex;
		//flex: 1;
		flex-direction: column;
		gap: 4px;
		height: 84px;
		justify-content: center;
		position: relative;
		width: 84px;
	}

	&.active {
	}
`;
// We pass "$isCountGreaterThanNine" because if the number is
// greater than 9 it will consistently shoe "9+" therefore,
// that will require different margin-left styling.
const WorkflowCountText = styled.div `
	${TypographyV3.Badge};
	color: ${ColorV3.Invert};
	margin-top: 2px;
	margin-left: ${({ $isCountGreaterThanNine }) => ($isCountGreaterThanNine ? "2px" : "0px")};
`;
const RootIcon = styled.div `
	& {
		height: 32px;
		width: 32px;
	}
`;
const RootTitle = styled.div `
	& {
	}

	${Root}.active & {
	}
`;
export const FooterNavButton = (p) => {
    const classList = [];
    if (p.isActive) {
        classList.push("active");
    }
    const Icon = p.icon;
    const colorRest = ColorV3.Invert;
    const colorActive = ColorV3.ButtonSecondary;
    return (_jsxs(Root, Object.assign({ className: classList.join(" "), onClick: p.onClick }, { children: [Boolean(p.count) && (_jsx(ChipSmall, Object.assign({ top: 8, right: 12 }, { children: _jsx(WorkflowCountText, Object.assign({ "$isCountGreaterThanNine": p.count > 9 }, { children: getCountFormatted(p.count) }), void 0) }), void 0)), _jsx(RootIcon, { children: _jsx(Icon, { size: 32, color: p.isActive ? colorActive : colorRest }, void 0) }, void 0), _jsx(RootTitle, { children: p.title }, void 0)] }), void 0));
};
const getCountFormatted = (count) => {
    if (count) {
        if (count > 9) {
            return "9+";
        }
        return count.toString();
    }
    return;
};
