import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { InvoiceRow } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRow";
import styled from "styled-components";
const RootContent = styled.div `
	& {
		${TypographyV3.Body};
	}
`;
export const InvoiceRowShipping = (p) => {
    return (_jsx(InvoiceRow
    //
    , { "$background": ColorV3.InputBackground, left: _jsx(RootContent, { children: "Shipping" }, void 0), right: _jsx(RootContent, { children: "Free" }, void 0) }, void 0));
};
