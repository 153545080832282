import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import styled from "styled-components";
export var ListCTAItemLabelColorType;
(function (ListCTAItemLabelColorType) {
    ListCTAItemLabelColorType["Success"] = "Success";
    ListCTAItemLabelColorType["Warning"] = "Warning";
})(ListCTAItemLabelColorType || (ListCTAItemLabelColorType = {}));
const colorsByLabelColorType = {
    [ListCTAItemLabelColorType.Success]: {
        color: ColorV3.StatusSuccess,
        backgroundColor: ColorV3.StatusSuccessBackground,
    },
    [ListCTAItemLabelColorType.Warning]: {
        color: ColorV3.StatusWarning,
        backgroundColor: ColorV3.StatusWarningBackground,
    },
};
function getColorStylesForLabelColorType({ $labelColorType }) {
    return colorsByLabelColorType[$labelColorType];
}
const Root = styled.div `
	${getColorStylesForLabelColorType}
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 8px;
	height: 22px;
	border-radius: 16px;
`;
const RootValue = styled.div `
	${TypographyV3.Badge};
`;
export const ListCTAItemLabel = ({ text, labelColorType = ListCTAItemLabelColorType.Success, isLoading }) => {
    if (!isLoading && text == undefined) {
        return null;
    }
    if (isLoading) {
        return _jsx(LoaderSpinner, { size: 22 }, void 0);
    }
    return (_jsx(Root, Object.assign({ "$labelColorType": labelColorType }, { children: _jsx(RootValue, { children: text }, void 0) }), void 0));
};
