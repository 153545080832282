import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStyle, getButtonStyleInfo } from "@/src/common/components/button/ButtonStyle";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import styled from "styled-components";
export const ButtonStandardRoot = styled.button `
	& {
		align-items: center;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		gap: 1ch;
		justify-content: center;
	}

	&.wide {
		flex: 1;
		width: 240px;
		max-width: 100%; // //min-width: 240px;
		margin: 0 auto;
	}

	&.disabled {
		cursor: inherit;
		opacity: 0.5;
	}

	${(p) => p.$info.Css};
`;
export const ButtonStandard = (p) => {
    var _a;
    const styleInfo = getButtonStyleInfo((_a = p.style) !== null && _a !== void 0 ? _a : ButtonStyle.SolidPrimary);
    const isLoading = Boolean(p.isLoading);
    const isDisabled = Boolean(p.isDisabled) || isLoading;
    const isForward = Boolean(p.isForward);
    const isSubmit = Boolean(p.isSubmit);
    const isWide = Boolean(p.isWide);
    return (_jsxs(ButtonStandardRoot
    //
    , Object.assign({ 
        //
        className: `${isDisabled && "disabled"} ${isLoading && "loading"} ${isWide && "wide"}`, form: p.form, "$info": styleInfo, onClick: p.onClick, type: isSubmit ? "submit" : "button" }, { children: [p.iconLeft && _jsx(Symbol, { icon: p.iconLeft }, void 0), p.content && _jsx("span", { children: p.content }, void 0), p.iconRight && _jsx(Symbol, { icon: p.iconRight }, void 0), isForward && _jsx(Symbol, { icon: "arrow_forward" }, void 0)] }), void 0));
};
