import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Check } from "@mui/icons-material";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: row;
		gap: 4px;
	}
`;
const RootIcon = styled.div `
	& {
	}

	& svg {
		color: ${ColorV3.Icon};
		font-size: 18px;
	}
`;
const RootContent = styled.div `
	& {
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.Body};
		font-weight: bold;
	}
`;
const RootDescription = styled.div `
	& {
		${TypographyV3.Body};
	}
`;
export const AdvisoryListItem = (p) => {
    const ins = p.instruction;
    return (_jsxs(Root, { children: [_jsx(RootIcon, { children: _jsx(Check, {}, void 0) }, void 0), _jsxs(RootContent, { children: [_jsx(RootTitle, { children: ins.title }, void 0), _jsx(RootDescription, { children: ins.description }, void 0)] }, void 0)] }, void 0));
};
