import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Badge};
		background: ${ColorV3.BadgeBackground};
		border-radius: 1rem;
		color: ${ColorV3.Badge};
		display: flex;
		padding: 2px 4px;
	}
`;
const RootText = styled.div ``;
export const Badge = (p) => {
    return (_jsx(Root, { children: _jsx(RootText, { children: p.children }, void 0) }, void 0));
};
