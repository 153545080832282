import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { getTopicInfo } from "@/src/modules/workflow/type/chat-v1/Topic";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 2px;
		padding: 4px 12px;
	}

	&:hover {
		cursor: pointer;
	}
`;
const RootHeader = styled.div `
	& {
		${TypographyV3.Button};
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;
const RootHeaderName = styled.div `
	& {
		color: ${ColorV3.ButtonPrimary};
		font-weight: 600;
	}

	${Root}:hover & {
		color: ${ColorV3.ButtonPrimaryHover};
	}
`;
const RootHeaderIcon = styled.div `
	& {
		color: ${ColorV3.ButtonPrimary};
	}

	${Root}:hover & {
		color: ${ColorV3.ButtonPrimaryHover};
	}
`;
const RootContent = styled.div `
	& {
		${TypographyV3.Hint};
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding-right: 24px;
	}
`;
export const TopicItem = (p) => {
    const topic = p.topic;
    const topicInfo = getTopicInfo(topic);
    // TODO: reidenzon - Implement expand/collapse.
    // const [isOpen, setIsOpen] = useState<boolean>(false);
    return (_jsxs(Root, Object.assign({ onClick: () => p.onAdd(topic) }, { children: [_jsxs(RootHeader, { children: [_jsx(RootHeaderName, { children: topicInfo.Name }, void 0), _jsx(RootHeaderIcon, { children: _jsx(Symbol, { icon: "chevron_right" }, void 0) }, void 0)] }, void 0), topicInfo.Description && _jsx(RootContent, { children: topicInfo.Description }, void 0)] }), void 0));
};
