import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const getGroupRolesAncestorByCode = (groupRoles, code) => {
    // console.log("getGroupRolesAncestorByCode", { groupRoles, code });
    for (const i in groupRoles) {
        const groupRole = groupRoles[i];
        for (const j in groupRole.groupAncestors) {
            const groupAncestor = groupRole.groupAncestors[j];
            if (groupAncestor.code == code) {
                return groupAncestor;
            }
        }
    }
    return undefined;
};
export const authApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: (headers, { getState }) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const token = getState().auth.token;
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: "login",
                method: "POST",
                body: credentials,
            }),
        }),
        protected: builder.mutation({
            query: () => "protected",
        }),
    }),
});
export const { useLoginMutation, useProtectedMutation } = authApi;
