import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { EmptyStatePageContentContainer } from "@/src/features/common/EmptyStatePageContentContainer";
import { LoadingPageFiller } from "@/src/features/common/LoadingPageFiller";
import { t } from "@/src/features/Localization";
import { useCreateThenRedirectToNewWorkflowRx } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflowRx";
import { OrderCardNoOrders } from "@/src/features/Workflows/Orders/OrderCardNoOrders";
import { OrderCards } from "@/src/features/Workflows/Orders/OrderCards";
import { useAuth } from "@/src/hooks/useAuth";
import { useCustomHistory } from "@/src/hooks/useCustomHistory";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { WorkflowTypeRxFillV3 } from "@/src/modules/workflow/type/rx-fill-v3/Type";
import { getWorkflowStatusInfo } from "@/src/modules/workflow/type/WorkflowStatus";
import { WorkflowStatusCategory } from "@/src/modules/workflow/type/WorkflowStatusCategory";
import { WorkflowTypeCategory } from "@/src/modules/workflow/type/WorkflowTypeCategory";
import emptyOrdersPng from "@/src/public/app/images/png/empty_orders.png";
import { useWorkflowListCustomerQuery } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import qs from "query-string";
import { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	& {
		margin: 64px 0;
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 64px;
	}
`;
const RootEmpty = styled.div ``;
const RootEmptyImage = styled.img `
	aspect-ratio: 600/446;
	height: 200px;
`;
export const OrdersList = () => {
    var _a;
    const { user, isUserInRelationshipWithDependents } = useAuth();
    const history = useHistory();
    const { segmentTrackEvent } = useSegmentAnalytics();
    const { historyPush } = useCustomHistory();
    const newWorkflowRxFill = useCreateThenRedirectToNewWorkflowRx();
    const queryString = qs.stringify({
        statusCategory: [WorkflowStatusCategory.Triage, WorkflowStatusCategory.Progress, WorkflowStatusCategory.Closed],
        typeCategory: [WorkflowTypeCategory.Rx, WorkflowTypeCategory.Service, WorkflowTypeCategory.Transfer],
    });
    const { data: getWorkflowListCustomerRequestData, isLoading: getWorkflowListCustomerIsLoading, isError: getWorkflowListCustomerIsError, refetch: refetchGetWorkflowListCustomer, } = useWorkflowListCustomerQuery({ queryString }, { skip: !(user === null || user === void 0 ? void 0 : user.guid) });
    const workflowList = ((_a = getWorkflowListCustomerRequestData === null || getWorkflowListCustomerRequestData === void 0 ? void 0 : getWorkflowListCustomerRequestData.data) === null || _a === void 0 ? void 0 : _a.workflows) || [];
    const workflowListOpen = [];
    const workflowListClosed = [];
    workflowList.forEach((w) => {
        const statusInfo = getWorkflowStatusInfo(w.status);
        const statusCategory = statusInfo.StatusCategory;
        if ([WorkflowStatusCategory.Triage, WorkflowStatusCategory.Progress].includes(statusCategory)) {
            workflowListOpen.push(w);
        }
        else if ([WorkflowStatusCategory.Closed].includes(statusCategory)) {
            workflowListClosed.push(w);
        }
    });
    function handlePrescription() {
        segmentTrackEvent("select_new_rx", {
            entrypoint: SegmentEntryPoint.Orders,
        });
        if (isUserInRelationshipWithDependents) {
            history.push(routes.WorkflowAdd.toPath({ type: WorkflowTypeRxFillV3 }));
        }
        else {
            newWorkflowRxFill.createThenRedirectToNewWorkflowRx();
        }
    }
    function handleChat() {
        history.push(routes.Chat.toPath());
        segmentTrackEvent("select_chat", {
            entrypoint: SegmentEntryPoint.Orders,
        });
    }
    const handleOnCardClick = (w) => {
        segmentTrackEvent("view_order_progress", {
            entrypoint: SegmentEntryPoint.Orders,
            patient_id: w.patient.guid,
        });
        historyPush(routes.WorkflowView.toPath({ workflowGuid: w.guid }));
    };
    useEffect(() => {
        refetchGetWorkflowListCustomer();
    }, [user]);
    if (getWorkflowListCustomerIsLoading) {
        return _jsx(LoadingPageFiller, {}, void 0);
    }
    const isEmpty = getWorkflowListCustomerIsError || (workflowListOpen.length === 0 && workflowListClosed.length === 0);
    return (_jsxs(Root, { children: [isEmpty && (_jsx(RootEmpty, { children: _jsx(EmptyStatePageContentContainer, { header: t("orders_noOrders_header"), image: _jsx(RootEmptyImage, { src: emptyOrdersPng }, void 0), footer: _jsxs(_Fragment, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "Fill new Prescription", onClick: handlePrescription }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, content: "Chat with Us", onClick: handleChat }, void 0)] }, void 0) }, void 0) }, void 0)), !isEmpty && (_jsxs(RootContent, { children: [_jsxs("div", Object.assign({ "data-cy": "pending-orders-group" }, { children: [_jsx(LayoutContentHeader, { title: t("orders_subheader_pending") }, void 0), workflowListOpen.length > 0 ? (_jsx(OrderCards, { workflows: workflowListOpen, handleOnCardClick: handleOnCardClick, isPendingWorkflow: true }, void 0)) : (_jsx(OrderCardNoOrders, {}, void 0))] }), void 0), _jsxs("div", { children: [_jsx(LayoutContentHeader, { title: t("orders_subheader_past") }, void 0), workflowListClosed.length > 0 ? _jsx(OrderCards, { workflows: workflowListClosed, handleOnCardClick: handleOnCardClick }, void 0) : _jsx(OrderCardNoOrders, {}, void 0)] }, void 0)] }, void 0))] }, void 0));
};
