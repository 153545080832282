import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { getDeepLinkFromURLSearchParams, redirectAfterLogin } from "@/src/features/DeepLink/DeepLinkHelpers";
import { SSOCustomerRequestType, SSOCustomerResultType, SSOMethodType } from "@/src/features/Login/types";
import { getSSOTokenRegister, setCurrentUserCredentials, setSSOCredentials, } from "@/src/features/RegisterAccount/RegisterAccountSSO/registerAccountSSOSlice";
import { SendSnackbar } from "@/src/features/utils/CustomSnackbar";
import { useCustomHistory } from "@/src/hooks/useCustomHistory";
import { setCredentials } from "@/src/redux/slices/authSlice";
import { routes } from "@/src/routes";
import { decodeGoogleResponseData, googleSSOInit, renderGoogleButton } from "@/src/utils/googleServices/googleAuth";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { SegmentTrackLoginMethod, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
const GOOGLE_SIGN_IN_BUTTON_ID = "google-sign-in-button-icon";
const SSOButtonGoogleContainer = styled.div `
	//width: 40px;
	//height: 40px;

	& {
		//border: 1px solid ${ColorV3.Divider};
		//border-radius: 4px;
		height: 38px;
		//display: flex;
		//align-items: center;
		//justify-content: center;
		//overflow: hidden;
		//cursor: pointer;
		//padding-right: 12px;
	}

	//& img {
	//	width: 38px;
	//}
`;
export const SSOButtonGoogle = (p) => {
    const [hasSignedIn, setHasSignedIn] = useState(false);
    const [isRequiresActivation, setIsRequiresActivation] = useState(false);
    const { query } = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();
    const ssoTokenRegister = useSelector(getSSOTokenRegister);
    const { segmentTrackEvent } = useSegmentAnalytics();
    const { historyReplace } = useCustomHistory();
    const institutionCode = (query === null || query === void 0 ? void 0 : query.get("groupCode")) || "";
    const pharmacyGuid = (query === null || query === void 0 ? void 0 : query.get("ph")) || undefined;
    const hasSSOTokenRegister = Boolean(ssoTokenRegister);
    function registerUser({ tokenRegister }) {
        dispatch(setSSOCredentials({ tokenRegister, ssoMethod: SSOMethodType.Google }));
        setHasSignedIn(true);
    }
    function activateUser({ tokenRegister }) {
        dispatch(setSSOCredentials({ tokenRegister, ssoMethod: SSOMethodType.Google }));
        setIsRequiresActivation(true);
    }
    function loginUser({ token, tokenStream, user, }) {
        // Ensure the user should have the action permission to login in.
        // and if so set the token credentials.
        if (token) {
            if (user && (user === null || user === void 0 ? void 0 : user.guid) && (user === null || user === void 0 ? void 0 : user.email)) {
                // Track successful registration
                segmentTrackEvent("login", {
                    guid: user === null || user === void 0 ? void 0 : user.guid,
                    email: user === null || user === void 0 ? void 0 : user.email,
                    method: SegmentTrackLoginMethod.Google,
                });
            }
            dispatch(setCredentials({
                token,
                tokenStream,
            }));
            redirectAfterLogin(history, query);
        }
    }
    function backendSSOLoginOrRegister(data) {
        const responseData = decodeGoogleResponseData(data);
        const authorizationToken = responseData.id_token;
        const nameFirst = responseData.given_name;
        const nameLast = responseData.family_name;
        fetch("/api/user-login-sso-customer", {
            method: "POST",
            body: JSON.stringify({
                type: SSOCustomerRequestType.SSOGoogle,
                authorization: authorizationToken,
            }),
        })
            .then((response) => response.json())
            .then((jsonResponse) => {
            var _a, _b;
            const { token, tokenStream } = (jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.session) || {};
            const { tokenRegister, result } = (jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.data) || {};
            const { code, messages } = jsonResponse || {};
            let user;
            if (code !== 200) {
                SendSnackbar.handleApiMessages(messages);
                return;
            }
            if ((_a = jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.data) === null || _a === void 0 ? void 0 : _a.user) {
                user = (_b = jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.data) === null || _b === void 0 ? void 0 : _b.user;
                dispatch(setCurrentUserCredentials({ user }));
            }
            else if (nameFirst && nameLast) {
                user = {
                    details: {
                        nameFirst,
                        nameLast,
                    },
                };
                dispatch(setCurrentUserCredentials({ user }));
            }
            // TODO set user here
            switch (result) {
                case SSOCustomerResultType.Login:
                    if (token && tokenStream) {
                        loginUser({
                            token,
                            tokenStream,
                            user: user,
                        });
                    }
                    break;
                case SSOCustomerResultType.Register:
                    if (tokenRegister) {
                        registerUser({ tokenRegister });
                    }
                    break;
                case SSOCustomerResultType.Activate:
                    if (tokenRegister) {
                        activateUser({ tokenRegister });
                    }
                    break;
                default:
                    break;
            }
        })
            .catch(() => {
            return null;
        });
    }
    // Use Effect to redirect user to register page
    useEffect(() => {
        // If the credentials have been set, redirect to sso page.
        if (hasSSOTokenRegister && hasSignedIn) {
            history.push(routes.RegisterAccountSSO.toPath({
                deeplink: getDeepLinkFromURLSearchParams(query),
                institutionCode: institutionCode,
                pharmacyGuid: pharmacyGuid,
            }));
        }
    }, [hasSSOTokenRegister, hasSignedIn]);
    // Use Effect to redirect user to actication page
    useEffect(() => {
        // If the credentials have been set, redirect to sso page.
        if (hasSSOTokenRegister && isRequiresActivation) {
            historyReplace(routes.LoginUserActivateSSO.toPath(), {
                tokenRegister: ssoTokenRegister,
                ssoMethod: SSOMethodType.Google,
            });
        }
    }, [hasSSOTokenRegister, isRequiresActivation, ssoTokenRegister]);
    useEffect(() => {
        googleSSOInit(backendSSOLoginOrRegister);
        renderGoogleButton(GOOGLE_SIGN_IN_BUTTON_ID, {
            text: p.isRegister ? "signup_with" : "continue_with",
        });
    }, []);
    return (_jsx(SSOButtonGoogleContainer, { id: GOOGLE_SIGN_IN_BUTTON_ID }, void 0));
};
