import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import styled from "styled-components";
const Root = styled.div `
	background: ${ColorV3.CardBackground};
	border-top: 1px solid ${ColorV3.Divider};
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 8px 0;
	width: 100%;
`;
const RootContent = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 12px;
		justify-content: center;
		max-width: 375px;
		margin: 0 auto;
		width: 100%;
	}
`;
/** @deprecated TODO: reidenzon - Replace with {@link LayoutFooter} -- make one!. */
export const Footer = (p) => {
    return (_jsx(Root, Object.assign({ "data-cy": "footer-container" }, { children: _jsx(RootContent, { children: p.children }, void 0) }), void 0));
};
