import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { PatientAvatar } from "@/src/features/Patient/PatientAvatar";
import { UserColor } from "@/src/modules/user/type/UserColor";
import { getUserDetailsNameFull } from "@/src/modules/user/type/UserDetails";
import { getWorkflowStatusInfo } from "@/src/modules/workflow/type/WorkflowStatus";
import { getWorkflowStatusColorInfo } from "@/src/modules/workflow/type/WorkflowStatusColor";
import { getWorkflowTypeInfo } from "@/src/modules/workflow/type/WorkflowType";
import { formatUnixTimestampToDate } from "@/src/utils/Time";
import styled from "styled-components";
const Root = styled.button `
	align-items: center;
	background: white;
	border: 1px solid ${ColorV3.Divider};
	border-radius: 3px;
	display: flex;
	flex-direction: row;
	gap: 12px;
	padding: 12px;
`;
const RootAvatar = styled.div ``;
const RootBody = styled.div `
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 12px;
	justify-content: space-between;
	min-width: 0;
`;
const RootContent = styled.div `
	${TypographyV3.Body};
	color: ${ColorV3.TextBody};
	position: relative;
	flex: 1;
	min-width: 0;
	text-align: left;
`;
const RootType = styled.div `
	font-weight: 600;
`;
const RootPatient = styled.div `
	position: relative;
	min-width: 0;
`;
const RootPatientOverflow = styled.div `
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;
const RootDate = styled.div `
	${TypographyV3.Hint};
`;
const RootBadge = styled.div `
	${TypographyV3.Badge};
	background: ${({ $colorBackground }) => $colorBackground !== null && $colorBackground !== void 0 ? $colorBackground : `${ColorV3.Disabled}`};
	padding: 2px 8px;
	height: 18px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const RootBadgeText = styled.div `
	color: ${({ $colorText }) => $colorText !== null && $colorText !== void 0 ? $colorText : `${ColorV3.Disabled}`};
`;
export const OrderCard = (p) => {
    var _a, _b;
    const workflow = p.workflow;
    const typeInfo = getWorkflowTypeInfo(workflow.type);
    const statusInfo = getWorkflowStatusInfo(workflow.status);
    const statusColorInfo = getWorkflowStatusColorInfo(statusInfo.Color);
    const profileIconColor = p.isPendingWorkflow ? p.workflow.patient.details.color : UserColor.Grey;
    return (_jsxs(Root, Object.assign({ onClick: () => p.handleOnCardClick(workflow) }, { children: [_jsx(RootAvatar, { children: _jsx(PatientAvatar, { color: profileIconColor }, void 0) }, void 0), _jsxs(RootBody, { children: [_jsxs(RootContent, { children: [_jsx(RootType, { children: (_a = typeInfo.TextCard) === null || _a === void 0 ? void 0 : _a.call(typeInfo, workflow) }, void 0), _jsx(RootPatient, { children: _jsx(RootPatientOverflow, { children: getUserDetailsNameFull(workflow.patient.details) }, void 0) }, void 0), _jsx(RootDate, { children: formatUnixTimestampToDate(workflow.timeReceived) }, void 0)] }, void 0), _jsx(RootBadge, Object.assign({ "$colorBackground": statusColorInfo.Background }, { children: _jsx(RootBadgeText, Object.assign({ "$colorText": statusColorInfo.Text }, { children: (_b = statusInfo.TextCard) === null || _b === void 0 ? void 0 : _b.call(statusInfo, workflow.details) }), void 0) }), void 0)] }, void 0)] }), workflow.guid));
};
