import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ModalFooterRowCenter } from "@/src/common/components/modal/ModalFooterRowCenter";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { t } from "@/src/features/Localization";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
const BoxStyled = styled(Box) `
	position: absolute;
	box-sizing: border-box;
	width: 327px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${ColorV3.CardBackground};
	border-radius: 7px;
`;
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px;
	}
`;
/**
 * @deprecated TODO: reidenzon - Replace with useWorkflowModalCancel instead!
 */
export const ConfirmDialog = (p) => {
    var _a;
    return (_jsx(Modal, Object.assign({ open: p.isOpen, onClose: p.handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsx(BoxStyled, { children: _jsxs(Root, { children: [_jsx(ModalHeader
                    //
                    , { 
                        //
                        title: p.titleText, description: p.informationText }, void 0), _jsxs(ModalFooterRowCenter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidGhost, content: (_a = p.buttonTextCancel) !== null && _a !== void 0 ? _a : t("signUp_dialog_cancel_button_no"), onClick: p.handleClose }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: p.buttonTextConfirm, onClick: p.handleSubmit }, void 0)] }, void 0)] }, void 0) }, void 0) }), void 0));
};
