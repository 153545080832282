import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { DividerLabel } from "@/src/common/components/layout/DividerLabel";
import { ConfirmDialog } from "@/src/features/common/ConfirmDialog";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { CssBackgroundImagesValues } from "@/src/features/cssBackgroundImages/cssBackgroundImageValues";
import { getDeeplinkFullUrl } from "@/src/features/DeepLink/DeepLinkHelpers";
import { t } from "@/src/features/Localization";
import { SSOSignInWithButtons } from "@/src/features/Login/SSOSignInWithButtons";
import { Nav } from "@/src/features/Nav/Nav";
import { RegisterAccountForm } from "@/src/features/RegisterAccount/RegisterAccountEmail/RegisterAccountForm";
import { RegisterPersonalInfoForm } from "@/src/features/RegisterAccount/RegisterAccountEmail/RegisterPersonalInfoForm";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { Benefits } from "@/src/modules/user/components/Benefits";
import { LoginAlternatives } from "@/src/modules/user/components/LoginAlernatives";
import { usePostUserRegisterBasicMutation } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { formatNumberToPhoneNumber } from "@/src/utils/HelperUtils";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { SegmentEntryPoint, SegmentTrackSignUpMethod, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	width: 322px;
	margin: 32px auto 0;
`;
const RootContent = styled.div `
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
export const RegisterAccountPage = () => {
    const history = useHistory();
    const { query } = useQuery();
    const { segmentTrackEvent } = useSegmentAnalytics();
    const [stepNumber, setStepNumber] = useState(0);
    const [cancelRegisterModalOpen, setCancelRegisterModalOpen] = useState(false);
    const institutionCode = (query === null || query === void 0 ? void 0 : query.get("groupCode")) || "";
    const pharmacyGuid = (query === null || query === void 0 ? void 0 : query.get("ph")) || undefined;
    const currentPage = stepNumber;
    const [state, setState] = useState({
        email: "",
        password: "",
        groupProvince: "",
        groupPharmacy: pharmacyGuid,
        nameFirst: "",
        nameLast: "",
        phone: "",
        isCheckbox: false,
    });
    const [postUserRegisterBasic, { isSuccess: userRegisterIsSuccess, data: userRegisterResponseData }] = usePostUserRegisterBasicMutation();
    function handleStep1(s) {
        setState(s);
        setStepNumber(stepNumber + 1);
    }
    function handleStep2(s) {
        setState(s);
        const deeplinkFullUrl = getDeeplinkFullUrl(query);
        postUserRegisterBasic({
            email: s.email,
            password: s.password,
            nameFirst: s.nameFirst,
            nameLast: s.nameLast,
            group: s.groupPharmacy || s.groupProvince,
            institutionCode: institutionCode,
            deeplink: deeplinkFullUrl,
            phone: formatNumberToPhoneNumber(s.phone),
        });
    }
    function handleGoBack() {
        segmentTrackEvent("select_back", {
            entrypoint: SegmentEntryPoint.Registration,
        });
        setStepNumber(stepNumber - 1);
    }
    function handleModalSubmit() {
        segmentTrackEvent("select_yes_cancel", {
            entrypoint: SegmentEntryPoint.Registration,
        });
        setCancelRegisterModalOpen(false);
        history.push(routes.Login.toPath());
    }
    function handleCancelRegister() {
        segmentTrackEvent("select_cancel", {
            entrypoint: SegmentEntryPoint.Registration,
        });
        setCancelRegisterModalOpen(true);
    }
    function handleSetModalClosed() {
        segmentTrackEvent("select_no_continue", {
            entrypoint: SegmentEntryPoint.Registration,
        });
        setCancelRegisterModalOpen(false);
    }
    useEffect(() => {
        var _a, _b;
        if (userRegisterIsSuccess) {
            const guid = (_b = (_a = userRegisterResponseData === null || userRegisterResponseData === void 0 ? void 0 : userRegisterResponseData.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.guid;
            segmentTrackEvent("sign_up", {
                guid,
                email: state.email,
                method: SegmentTrackSignUpMethod.Basic,
            });
            history.push(routes.RegisterComplete.toPath({
                email: state.email,
            }));
        }
    }, [userRegisterIsSuccess, userRegisterResponseData]);
    useEffect(() => {
        segmentTrackEvent("select_signup");
    }, []);
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: currentPage === 1 ? { leftItemType: "Back", onClick: handleGoBack } : { leftItemType: "Cancel", onClick: handleCancelRegister } }, "Nav"), _jsx(PageOverflowContainer, Object.assign({ "$backgroundImages": CssBackgroundImagesValues.Page.LoginAndSignup }, { children: _jsx(PageWrapper, Object.assign({ isDisableVerticalPadding: true }, { children: _jsxs(Root, { children: [currentPage === 0 && (_jsxs(_Fragment, { children: [_jsx(LayoutContentHeader
                                    //
                                    , { 
                                        //
                                        title: t("signUp_email_step1_incentive_header"), description: t("signUp_email_step1_incentive_subheader") }, void 0), _jsxs(RootContent, { children: [_jsx(RegisterAccountForm, { onSubmit: handleStep1, state: state }, void 0), _jsx(DividerLabel, {}, void 0), _jsx(SSOSignInWithButtons, { isRegister: true }, void 0)] }, void 0), _jsx(LoginAlternatives
                                    //
                                    , { 
                                        //
                                        entrypoint: SegmentEntryPoint.Registration, isLogin: true, isPhone: true }, void 0)] }, void 0)), currentPage === 1 && (_jsxs(_Fragment, { children: [_jsx(LayoutContentHeader
                                    //
                                    , { 
                                        //
                                        title: t("signUp_email_step2_incentive_header"), description: _jsx(Benefits, {}, void 0) }, void 0), _jsx(RootContent, { children: _jsx(RegisterPersonalInfoForm, { onSubmit: handleStep2, state: state }, void 0) }, void 0)] }, void 0))] }, void 0) }), void 0) }), void 0), _jsx(ConfirmDialog, { isOpen: cancelRegisterModalOpen, handleClose: handleSetModalClosed, handleSubmit: handleModalSubmit, titleText: t("signUp_dialog_cancel_header"), informationText: t("signUp_dialog_cancel_content"), buttonTextConfirm: t("signUp_dialog_cancel_button_yes"), buttonTextCancel: t("signUp_dialog_cancel_button_no") }, void 0)] }, void 0));
};
