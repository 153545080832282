import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import { getWorkflowStatusInfo } from "@/src/modules/workflow/type/WorkflowStatus";
import { getWorkflowStatusColorInfo } from "@/src/modules/workflow/type/WorkflowStatusColor";
import { getWorkflowTypeInfo } from "@/src/modules/workflow/type/WorkflowType";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useHistory } from "react-router";
import styled, { keyframes } from "styled-components";
const Root = styled.button `
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-between;
	padding: 8px 16px;
	align-items: center;
	border-radius: 4px;
	border: ${({ $colorBorder }) => ($colorBorder ? `0.5px solid ${$colorBorder};` : `0.5px solid ${ColorV3.Divider};`)};
	gap: 12px;
	background-color: ${ColorV3.CardBackground};

	&:hover {
		opacity: 65%;
	}

	:focus {
		color: ${({ $colorBorder }) => ($colorBorder ? `${$colorBorder};` : `${ColorV3.Divider};`)};
		background-color: ${({ $colorBackground }) => ($colorBackground ? `${$colorBackground};` : `${ColorV3.CardBackground}`)};

		border: ${({ $colorBorder }) => ($colorBorder ? `0.5px solid ${$colorBorder};` : `0.5px solid ${ColorV3.Divider};`)};
		outline: 0;
	}
`;
const ContentContainer = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
`;
const RootDot = styled.div `
	background-color: ${({ $workflowStatusIconColor }) => ($workflowStatusIconColor ? `${$workflowStatusIconColor};` : `${ColorV3.Divider}`)};
	width: 8px;
	height: 8px;
	border-radius: 50%;
`;
const RootLabel = styled.div `
	${TypographyV3.Body};
	font-weight: 600;
`;
const RootBadge = styled.div `
	background-color: ${({ $colorBackground }) => ($colorBackground ? `${$colorBackground};` : `${ColorV3.Divider}`)};
	padding: 2px 8px;
	height: 18px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const RootBadgeText = styled.div `
	${TypographyV3.Badge};
	color: ${({ $colorText }) => ($colorText ? `${$colorText};` : `${ColorV3.Divider}`)};
`;
const animatedArrowKeyframeLeftPosition = "-4px";
const breatheAnimation = keyframes `
	0% {
		transform: translate(0, 0);
	}
	80% {
		transform: translate(0, 0);
	}
	85% {
		transform: translate(${animatedArrowKeyframeLeftPosition}, 0);
	}
	90% {
		transform: translate(0, 0);
	}
	95% {
		transform: translate(${animatedArrowKeyframeLeftPosition}, 0);
	}
	100% {
		transform: translate(0, 0);
	}
`;
const RootIcon = styled.div `import {
	t
}

	from "@/src/features/Localization";
	animation-name: ${breatheAnimation};
	animation-duration: 4s; // 4s hold, 1s move -4px (i.e. to left), 1s move right to 0, 1s move -4px, 1s move back right;
	animation-iteration-count: infinite;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const RootArrow = styled(Icons.ArrowForwardRound) `
	width: 24px;
	height: 24px;
`;
export const DynamicActionBar = (p) => {
    var _a, _b;
    const history = useHistory();
    const segment = useSegmentAnalytics();
    const workflow = (_a = p.rxWorkflows) === null || _a === void 0 ? void 0 : _a[0];
    if (!workflow) {
        return _jsx(_Fragment, {}, void 0);
    }
    function handleWorkflowDetailsChange(workflowGuid) {
        segment.segmentTrackEvent("view_order_progress", {
            entrypoint: SegmentEntryPoint.HomePage,
            patient_id: workflow === null || workflow === void 0 ? void 0 : workflow.patient.guid,
        });
        history.push(routes.WorkflowView.toPath({ workflowGuid }));
    }
    const typeInfo = getWorkflowTypeInfo(workflow.type);
    const statusInfo = getWorkflowStatusInfo(workflow.status);
    const statusColorInfo = getWorkflowStatusColorInfo(statusInfo.Color);
    return (_jsxs(Root, Object.assign({ onClick: () => handleWorkflowDetailsChange(workflow.guid), "$colorBorder": statusColorInfo.Border, "$colorBackground": statusColorInfo.Background }, { children: [_jsxs(ContentContainer, { children: [_jsx(RootDot, { "$workflowStatusIconColor": statusColorInfo.Icon }, void 0), _jsx(RootLabel, { children: typeInfo.TextDab }, void 0), _jsx(RootBadge, Object.assign({ "$colorBackground": statusColorInfo.Background }, { children: _jsx(RootBadgeText, Object.assign({ "$colorText": statusColorInfo.Text }, { children: (_b = statusInfo.TextDab) === null || _b === void 0 ? void 0 : _b.call(statusInfo, workflow.details) }), void 0) }), void 0)] }, void 0), _jsx(RootIcon, { children: _jsx(Symbol, { icon: "arrow_forward", color: statusColorInfo.Icon }, void 0) }, void 0)] }), void 0));
};
