import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { ColorV3 } from "@/src/common/style/StyleColor";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		cursor: pointer;
		display: flex;
		justify-content: center;
		font-size: 10px;
		height: 24px;
		width: 18px;
	}
`;
export const DotStep = (p) => {
    return (_jsxs(Root, Object.assign({ onClick: p.onClick }, { children: [!p.isActive && _jsx(Symbol, { icon: "circle", color: ColorV3.TextHint, isFill: true }, void 0), p.isActive && _jsx(Symbol, { icon: "circle", color: ColorV3.ButtonPrimary, isFill: true }, void 0)] }), void 0));
};
