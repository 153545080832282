import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.header `
	& {
		//background: ${ColorV3.HeaderBackground};
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	&.margin {
		margin-bottom: 32px;
	}
`;
const RootTitle = styled.header `
	& {
		${TypographyV3.Header1}
	}
`;
const RootDescription = styled.div `
	& {
		${TypographyV3.Body};
		display: flex;
		flex-direction: column;
		gap: 1em;
	}
`;
/**
 * TODO: reidenzon - Come up with a better name!
 */
export const LayoutContentHeader = (p) => {
    var _a;
    const isHeader = Boolean(p.title) || Boolean(p.description);
    const isMargin = (_a = p.isMargin) !== null && _a !== void 0 ? _a : true;
    if (!isHeader) {
        return null;
    }
    return (_jsxs(Root, Object.assign({ className: `${isMargin && "margin"}` }, { children: [p.title && _jsx(RootTitle, { children: p.title }, void 0), p.description && _jsx(RootDescription, { children: p.description }, void 0)] }), void 0));
};
