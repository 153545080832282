import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		position: fixed;
		background: rgba(0, 0, 0, 0.5);
		bottom: 0;
		display: flex;
		justify-content: center;
		left: 0;
		right: 0;
		top: 0;
		z-index: 1000;
	}
`;
const RootWrapper = styled.div `
	& {
		display: flex;
		justify-content: center;
		max-height: 100vh;
		max-width: 375px;
		width: 100%;
	}
`;
const RootContent = styled.div `
	& {
		background: ${ColorV3.CardBackground};
		border-radius: 8px;
		margin: 12px;
		overflow: auto;
	}
`;
export const Modal = (p) => {
    const content = p.modal.content;
    const handleClose = () => {
        p.onClose();
    };
    return (_jsx(Root, Object.assign({ onClick: handleClose }, { children: _jsx(RootWrapper, { children: _jsx(RootContent, Object.assign({ onClick: (e) => e.stopPropagation() }, { children: content({
                    onClose: handleClose,
                }) }), void 0) }, void 0) }), void 0));
};
