var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { ErrorMessage } from "@/src/features/common/ErrorMessage";
import { resolveValue } from "@/src/utils/PathValue";
import { useFormikContext } from "formik";
import styled, { css } from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
const RootInput = styled.textarea `
	${TypographyV3.FieldInput};
	width: 100%;
	box-sizing: border-box;
	height: 124px;
	background: ${ColorV3.InputBackground};
	border: 1px solid ${ColorV3.InputPrimary};
	border-radius: 8px;
	padding: 12px 16px;
	resize: vertical;

	// If the textarea is empty and not focused
	// Then give it a grey background
	${({ $isEmpty }) => $isEmpty
    ? css `
					&:not(:focus-visible) {
						background: ${ColorV3.InputBackgroundEmpty};
						border-color: transparent;
					}
			  `
    : ""}
`;
export const FormikTextarea = (p) => {
    const formik = useFormikContext();
    const formikId = p.formikId;
    const errorMessage = resolveValue(formik.touched, formikId) && resolveValue(formik.errors, formikId);
    const value = resolveValue(formik.values, formikId);
    const isEmpty = !(value && value !== "");
    const handleBlur = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        yield formik.setFieldValue(formikId, (_a = event.target.value) === null || _a === void 0 ? void 0 : _a.trim());
        formik.handleBlur(event);
    });
    const handleChange = (event) => __awaiter(void 0, void 0, void 0, function* () {
        yield formik.setFieldValue(formikId, event.target.value);
    });
    return (_jsxs(Root, { children: [_jsx(RootInput, { className: "FormikTextField", id: formikId, name: formikId, value: resolveValue(formik.values, formikId), onBlur: handleBlur, onChange: handleChange, placeholder: p.placeholder, "$hasErrorMessage": Boolean(errorMessage), "$isEmpty": isEmpty }, void 0), errorMessage && _jsx(ErrorMessage, { error: errorMessage }, void 0)] }, void 0));
};
