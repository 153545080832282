import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { getHoursMapAsScheduleShort } from "@/src/common/utility/hours/HoursMap";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		background-color: ${ColorV3.BadgeBackground};
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 12px;
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.Header3};
	}
`;
const RootLine = styled.div `
	& {
		${TypographyV3.Body};
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 2px;
	}
`;
const RootDays = styled.div `
	& {
		${TypographyV3.Body};
		font-weight: 600;
	}
`;
const RootHourList = styled.div `
	& {
		${TypographyV3.Body};
	}
`;
export const ChatHoursBox = (p) => {
    if (!p.hours) {
        return null;
    }
    return (_jsxs(Root, { children: [_jsx(RootTitle, { children: p.title }, void 0), getHoursMapAsScheduleShort(p.hours).map((s) => {
                return (_jsxs(RootLine, { children: [_jsx(RootDays, { children: s.days }, void 0), _jsx(RootHourList, { children: s.hoursList.map((h) => {
                                return _jsx("div", { children: h }, h);
                            }) }, void 0)] }, s.key));
            })] }, void 0));
};
