import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldHeader } from "@/src/common/components/field/FieldHeader";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-top: 12px;
	}
`;
export const ServiceListSection = (p) => {
    return (_jsxs(Root, { children: [_jsx(FieldHeader
            //
            , { 
                //
                title: p.title, description: p.description }, void 0), _jsx(RootContent, { children: p.children }, void 0)] }, void 0));
};
