import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { FieldDescription } from "@/src/common/components/field/FieldDescription";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { useWorkflowModalTip } from "@/src/modules/workflow/layout/workflow/modal/WorkflowModalTip";
import { InvoiceRow } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRow";
import { getWorkflowStatusInfo } from "@/src/modules/workflow/type/WorkflowStatus";
import styled from "styled-components";
const RootName1 = styled.div `
	& {
		${TypographyV3.Body};
	}
`;
const RootPrice = styled.div `
	& {
		${TypographyV3.Body};
	}
`;
export const InvoiceRowTip = (p) => {
    var _a;
    const workflow = p.workflow;
    const payment = p.payment;
    const isEdit = getWorkflowStatusInfo(workflow.status).InvoiceIsEditTip;
    const tip = (_a = payment.tip) !== null && _a !== void 0 ? _a : 0;
    const price = (tip / 100).toFixed(2);
    const modalTip = useWorkflowModalTip();
    return (_jsx(InvoiceRow
    //
    , { 
        //
        left: _jsxs(RootName1, { children: [_jsx("div", { children: "Tip" }, void 0), _jsx(FieldDescription, { children: "100% of your tip goes to your courier." }, void 0)] }, void 0), right: _jsxs(_Fragment, { children: [_jsx(RootPrice, { children: `$${price}` }, void 0), isEdit && (_jsx(ButtonStandard
                //
                , { 
                    //
                    content: "Edit", style: ButtonStyle.TextAnchor, onClick: () => {
                        modalTip.doModal({
                            state: {
                                price: tip / 100,
                            },
                            onConfirm: (s) => {
                                p.onEdit(s.price * 100);
                            },
                        });
                    } }, void 0))] }, void 0) }, void 0));
};
