import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { LoadingPageFiller } from "@/src/features/common/LoadingPageFiller";
import { useAuth } from "@/src/hooks/useAuth";
import { NotificationCategory } from "@/src/modules/notification/type/NotificationCategory";
import { Notification } from "@/src/modules/user/layout/notification-settings/Notification";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
`;
const Divider = styled.div `
	border: 1px solid ${ColorV3.Divider};
`;
export const NotificationList = (p) => {
    var _a;
    const auth = useAuth();
    const user = auth.user;
    const nc = ((_a = user === null || user === void 0 ? void 0 : user.preferences) === null || _a === void 0 ? void 0 : _a.notifications) || {};
    // const isGlobal = nc[NotificationCategory.Global]?.isPush ?? false;
    if (!user) {
        return _jsx(LoadingPageFiller, {}, void 0);
    }
    return (_jsxs(Root, { children: [_jsx(Notification, { category: NotificationCategory.Global, notifications: nc }, void 0), _jsx(Divider, {}, void 0), [
                //
                NotificationCategory.Chat,
                NotificationCategory.Order,
                NotificationCategory.Refill,
                // NotificationCategory.Promo,
            ].map((c) => {
                return _jsx(Notification, { category: c, notifications: nc }, c);
            })] }, void 0));
};
