import { jsx as _jsx } from "react/jsx-runtime";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Body};

		align-items: center;
		background: ${ColorV3.ButtonSecondary};
		border-radius: 16px;
		//color: ${ColorV3.Invert};
		display: flex;
		font-size: 0.8em;
		padding: 0 0.5em;
	}
`;
export const NavDrawerBadge = (p) => {
    return _jsx(Root, { children: "New Feature!" }, void 0);
};
