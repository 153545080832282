import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Field } from "@/src/common/components/field/Field";
import { FieldList } from "@/src/common/components/field/FieldList";
import { ModalFooterColumn } from "@/src/common/components/modal/ModalFooterColumn";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { ModalContext } from "@/src/common/components/modal/ModalProvider";
import { ColorV3 } from "@/src/common/style/StyleColor";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { translate } from "@/src/common/utility/translation/Translate";
import { FormikTextarea } from "@/src/features/utils/Formik/FormikTextarea";
import { getUserDetailsNameFull } from "@/src/modules/user/type/UserDetails";
import { Formik } from "formik";
import { useContext } from "react";
import styled from "styled-components";
import * as Yup from "yup";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px;
	}
`;
const RootAddress = styled.div `
	& {
		${TypographyV3.Hint};
		align-items: center;
		border: 1px solid ${ColorV3.Divider};
		border-radius: 8px;
		display: flex;
		padding: 8px 16px;
		text-align: center;
	}
`;
export const WorkflowModalAddress = (p) => {
    const state = p.state;
    const workflow = p.workflow;
    // const formikId = generateGuid();
    const formikInitialValues = {
        deliveryAddressNotes: state.deliveryAddressNotes,
    };
    const formikConfig = {
        enableReinitialize: true,
        initialValues: formikInitialValues,
        validateOnBlur: true,
        validationSchema: Yup.object().shape({
        // TODO: reidenzon - Do something?!
        }),
        onSubmit: (v) => {
            // TODO: reidenzon - Do something?!
        },
    };
    return (_jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsxs(Root, { children: [_jsx(ModalHeader, { onClose: p.onClose, title: translate("Make this the default address for {name}?", {
                        name: getUserDetailsNameFull(workflow.patient.details),
                    }) }, void 0), _jsxs(FieldList, { children: [_jsx(RootAddress, { children: state.deliveryAddress }, void 0), _jsx(Field
                        //
                        , Object.assign({ 
                            //
                            title: "Delivery Notes", description: "This will speed up your future checkouts. You can change a default address at any time from your profile section." }, { children: _jsx(FormikTextarea, { formikId: "deliveryAddressNotes", placeholder: "Delivery notes (ex Buzzer 291)" }, void 0) }), void 0)] }, void 0), _jsxs(ModalFooterColumn, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "Set as Default Address", onClick: () => {
                                var _a, _b;
                                (_a = p.onSetDefault) === null || _a === void 0 ? void 0 : _a.call(p, Object.assign(Object.assign({}, state), { deliveryAddressNotes: (_b = formik.values.deliveryAddressNotes) === null || _b === void 0 ? void 0 : _b.trim() }));
                            } }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, content: "Only use address for this order", onClick: () => {
                                var _a, _b;
                                (_a = p.onThisOrder) === null || _a === void 0 ? void 0 : _a.call(p, Object.assign(Object.assign({}, state), { deliveryAddressNotes: (_b = formik.values.deliveryAddressNotes) === null || _b === void 0 ? void 0 : _b.trim() }));
                            } }, void 0)] }, void 0)] }, void 0)) }), void 0));
};
export const useWorkflowModalAddress = () => {
    const mc = useContext(ModalContext);
    return {
        doModal: (p) => {
            mc.AddModal({
                guid: generateGuid(),
                content: (m) => {
                    return (_jsx(WorkflowModalAddress, { onClose: () => {
                            m.onClose();
                        }, onSetDefault: (s) => {
                            var _a;
                            (_a = p.onSetDefault) === null || _a === void 0 ? void 0 : _a.call(p, s);
                            m.onClose();
                        }, onThisOrder: (s) => {
                            var _a;
                            (_a = p.onThisOrder) === null || _a === void 0 ? void 0 : _a.call(p, s);
                            m.onClose();
                        }, state: p.state, workflow: p.workflow }, void 0));
                },
            });
        },
    };
};
