import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getAdvisoryStyleInfo } from "@/src/common/components/advistory/AdvisoryStyle";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Body};
		align-items: start;
		background: ${(p) => p.$info.ColorBackground};
		border-radius: 8px;
		display: flex;
		flex-direction: row;
		gap: 4px;
		padding: 16px;
		width: 240px;
	}

	& svg {
		color: ${(p) => p.$info.ColorTitle};
	}
`;
const RootIcon = styled.div `
	& {
	}
`;
const RootRight = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
`;
const RootTitle = styled.div `
	& {
		color: ${(p) => p.$info.ColorTitle};
		font-weight: 600;
		margin-top: 4px;
	}
`;
const RootContent = styled.div `
	& {
	}
`;
export const AdvisoryBox = (p) => {
    const styleInfo = getAdvisoryStyleInfo(p.style);
    return (_jsxs(Root, Object.assign({ "$info": styleInfo }, { children: [p.icon && _jsx(RootIcon, { children: p.icon }, void 0), _jsxs(RootRight, { children: [p.title && _jsx(RootTitle, Object.assign({ "$info": styleInfo }, { children: p.title }), void 0), p.content && _jsx(RootContent, { children: p.content }, void 0)] }, void 0)] }), void 0));
};
